<template>
  <div class="flex flex-col justify-center bg-white p-4 rounded-sm mb-4 sm:mb-0 sm:ml-4 w-full sm:w-1/3">
    <div v-if="user">
      <p class="text-center text-primary italic text-xl">{{ user.c_first_name }} {{ user.c_last_name }}</p>
    </div>
    <div class="text-center text-primary italic text-2xl font-semibold">Votre Commande</div>
    <div class="flex flex-col justify-around divide-y divide-secondary h-full">
      <div v-if="isSubscriptionSelected" class="px-6 py-4 text-center text-lg font-semibold grow h-full">
        <div class="text-primary">{{ selectedSubscription?.title }}</div>
        <div>
          {{ paymentsHelpers.getFormattedPrice(selectedSubscription?.price, selectedSubscription?.paymentFrequency) }}
        </div>
        <div>{{ selectedSubscription?.subscription }}</div>
      </div>
      <div v-else class="grow h-full flex flex-col justify-center">
        <div>
          <p class="text-center">-</p>
        </div>
      </div>

      <div v-if="isSubscription" class="px-6 py-4 text-center text-lg font-semibold grow h-full">
        <div class="text-primary">{{ selectedDelivery.title }}</div>
        <div>
          {{
            paymentsHelpers.getFormattedDeliveryPrice(
              selectedCountry,
              selectedDelivery,
              selectedSubscription?.deliveryFrequency
            )
          }}
        </div>
      </div>
      <div v-else-if="isCart">
        {{ '' }}
      </div>
      <div v-else class="grow h-full flex flex-col justify-center">
        <div>
          <p class="text-center">-</p>
        </div>
      </div>

      <div class="px-6 py-4 text-center text-lg font-semibold grow-0">
        <div class="text-lg font-semibold">Total de la commande</div>
        <div v-if="isSubscription">
          {{
            '= ' +
            paymentsHelpers.getFormattedPrice(
              paymentsHelpers.getTotalSubscriptionPrice(
                props.selectedSubscription,
                props.selectedCountry,
                props.selectedDelivery
              ),
              props.selectedSubscription?.paymentFrequency
            )
          }}
        </div>
        <div v-else-if="isCart">
          {{ '= ' }}
        </div>
        <div v-else class="h-full">
          <p class="text-center">-</p>
        </div>
      </div>
    </div>
    <Div
      class="mt-8"
      :tooltipText="!isOrderValid ? 'Veuillez renseigner un type d\'abonnement et un mode de livraison' : null"
      isFull
    >
      <Button @clicked="$emit('validate')" :disabled="!isOrderValid" :loading="props.loading" class="cursor-pointer">
        VALIDER
      </Button>
    </Div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import Button from '@/components/Button'
import Div from '@/components/Div'
import paymentsHelpers from '@/helpers/payments'

const props = defineProps({
  loading: { type: Boolean, required: true },
  selectedSubscription: { type: Object, required: false },
  selectedCountry: { type: Object, required: true },
  selectedDelivery: { type: Object, required: true }
})

const isSubscriptionSelected = computed(() => {
  return props.selectedSubscription && Object.keys(props.selectedSubscription).length > 0
})

const isCountrySelected = computed(() => {
  return props.selectedCountry && Object.keys(props.selectedCountry).length > 0
})

const isDeliverySelected = computed(() => {
  return props.selectedDelivery && Object.keys(props.selectedDelivery).length > 0
})

const isSubscription = computed(() => {
  return isSubscriptionSelected.value && isCountrySelected.value && isDeliverySelected.value
})

const isCart = computed(() => {
  return props.selectedDelivery && isDeliverySelected.value
})

const isOrderValid = computed(() => {
  return isSubscription.value || isCart.value
})
</script>
