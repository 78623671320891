<template>
  <div
    @click="!disabled && $emit('clicked', $event)"
    :class="[
      disabled ? 'bg-opacity-50 hover:bg-opacity-50 cursor-not-allowed' : 'hover:bg-opacity-80 cursor-pointer',
      danger ? 'bg-error' : 'bg-secondary'
    ]"
    class="w-full h-full flex flex-col justify-center text-center py-2 px-4 border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tertiary"
  >
    <div>
      <div v-if="loading" class="spinner mx-auto" />
      <span v-else>
        <slot></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    danger: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.spinner {
  border: 2px solid transparent;
  border-top: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  height: 20px;
  width: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
