<template>
  <div class="flex flex-col mx-5 lg:mx-10 mt-10">
    <!-- Search and Filters section -->
    <div class="flex bg-white rounded-sm mb-5 p-6 flex-wrap items-center justify-center">
      <!-- Search text field -->
      <div class="flex flex-col justify-center items-start w-80 m-2">
        <span class="text-sm md:text-base font-medium text-gray-700">Recherche</span>
        <input
          v-model="search"
          class="w-80 cursor-default rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary text-sm md:text-base placeholder-gray-500"
          placeholder="Région, appellation, cépage..."
        />
      </div>
      <!-- Category Filter -->
      <!-- <DropdownFilter
        v-model="categoriesSelected"
        :options="categoriesOptions"
        label="Catégories"
        placeholder="Sélectionnez une catégorie"
        :multiple="true"
      /> -->
      <Listbox
        as="div"
        class="flex flex-col justify-center items-start m-2 w-80"
        v-model="categoriesSelected"
        v-slot="{ open }"
        multiple
      >
        <ListboxLabel class="block text-sm md:text-base font-medium text-gray-700">Catégories</ListboxLabel>
        <div class="relative w-full">
          <ListboxButton
            class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary text-sm md:text-base"
          >
            <span class="flex items-center">
              <span
                class="block truncate"
                :class="{ 'text-gray-500': !categoriesSelected || categoriesSelected.length === 0 }"
                >{{
                  categoriesSelected && categoriesSelected.length > 0
                    ? categoriesSelected.map(c => c.name).join(', ')
                    : 'Sélectionnez une catégorie'
                }}</span
              >
            </span>
            <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <font-awesome-icon v-if="open" icon="fa-solid fa-angle-up" class="h-5 w-5 text-gray-400" />
              <font-awesome-icon v-else icon="fa-solid fa-angle-down" class="h-5 w-5 text-gray-400" />
            </span>
          </ListboxButton>
          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm md:text-base"
            >
              <ListboxOption
                as="template"
                v-for="category in categoriesOptions"
                :key="category.id"
                :value="category"
                v-slot="{ active, selected }"
              >
                <li
                  :class="[
                    active ? 'text-white bg-primary' : 'text-gray-900',
                    'relative cursor-default select-none py-2 pl-3 pr-9'
                  ]"
                >
                  <div class="flex items-center">
                    <div :class="['w-5 h-5 mx-2 rounded-full', `${category.color}`]"></div>
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{
                      category.name
                    }}</span>
                  </div>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-primary',
                      'absolute inset-y-0 right-0 flex items-center pr-4'
                    ]"
                  >
                    <font-awesome-icon icon="fa-solid fa-check" class="h-5 w-5" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <!-- Price Filter -->
      <Listbox
        as="div"
        class="flex flex-col justify-center items-start m-2 w-80"
        v-model="priceRangesSelected"
        v-slot="{ open }"
        multiple
      >
        <ListboxLabel class="block text-sm md:text-base font-medium text-gray-700">Prix</ListboxLabel>
        <div class="relative w-full">
          <ListboxButton
            class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary text-sm md:text-base"
          >
            <span class="flex items-center">
              <span
                class="block truncate"
                :class="{
                  'text-gray-500': !priceRangesSelected || priceRangesSelected.length === 0
                }"
                >{{
                  priceRangesSelected && priceRangesSelected.length > 0
                    ? priceRangesSelected.map(c => c.name).join(', ')
                    : 'Sélectionnez un prix'
                }}</span
              >
            </span>
            <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <font-awesome-icon v-if="open" icon="fa-solid fa-angle-up" class="h-5 w-5 text-gray-400" />
              <font-awesome-icon v-else icon="fa-solid fa-angle-down" class="h-5 w-5 text-gray-400" />
            </span>
          </ListboxButton>
          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm md:text-base"
            >
              <ListboxOption
                as="template"
                v-for="price in priceRangesOptions"
                :key="price.id"
                :value="price"
                v-slot="{ active, selected }"
              >
                <li
                  :class="[
                    active ? 'text-white bg-primary' : 'text-gray-900',
                    'relative cursor-default select-none py-2 pl-3 pr-9'
                  ]"
                >
                  <div class="flex items-center">
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{
                      price.name
                    }}</span>
                  </div>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-primary',
                      'absolute inset-y-0 right-0 flex items-center pr-4'
                    ]"
                  >
                    <font-awesome-icon icon="fa-solid fa-check" class="h-5 w-5" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <!-- Country Filter -->
      <Listbox
        as="div"
        class="flex flex-col justify-center items-start m-2 w-80"
        v-model="countriesSelected"
        v-slot="{ open }"
        multiple
      >
        <ListboxLabel class="block text-sm md:text-base font-medium text-gray-700">Pays</ListboxLabel>
        <div class="relative w-full">
          <ListboxButton
            class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary text-sm md:text-base"
          >
            <span class="flex items-center">
              <span
                class="block truncate"
                :class="{ 'text-gray-500': !countriesSelected || countriesSelected.length === 0 }"
                >{{
                  countriesSelected && countriesSelected.length > 0
                    ? countriesSelected.map(c => c.name).join(', ')
                    : 'Sélectionnez un pays'
                }}</span
              >
            </span>
            <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <font-awesome-icon v-if="open" icon="fa-solid fa-angle-up" class="h-5 w-5 text-gray-400" />
              <font-awesome-icon v-else icon="fa-solid fa-angle-down" class="h-5 w-5 text-gray-400" />
            </span>
          </ListboxButton>
          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm md:text-base"
            >
              <ListboxOption
                as="template"
                v-for="country in countriesOptions"
                :key="country.id"
                :value="country"
                v-slot="{ active, selected }"
              >
                <li
                  :class="[
                    active ? 'text-white bg-primary' : 'text-gray-900',
                    'relative cursor-default select-none py-2 pl-3 pr-9'
                  ]"
                >
                  <div class="flex items-center flex-row">
                    <Flag :country="country.name" class="mx-2" />
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                      {{ country.name }}</span
                    >
                  </div>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-primary',
                      'absolute inset-y-0 right-0 flex items-center pr-4'
                    ]"
                  >
                    <font-awesome-icon icon="fa-solid fa-check" class="h-5 w-5" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <!-- Selections Filter -->
      <Listbox
        as="div"
        class="flex flex-col justify-center items-start m-2 w-80"
        v-model="selectionsSelected"
        v-slot="{ open }"
        multiple
      >
        <ListboxLabel class="block text-sm md:text-base font-medium text-gray-700">Sélections</ListboxLabel>
        <div class="relative w-full">
          <ListboxButton
            class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary text-sm md:text-base"
          >
            <span class="flex items-center">
              <span
                class="block truncate"
                :class="{ 'text-gray-500': !selectionsSelected || selectionsSelected.length === 0 }"
                >{{
                  selectionsSelected && selectionsSelected.length > 0
                    ? selectionsSelected.map(c => c.name).join(', ')
                    : 'Sélectionnez une sélection'
                }}</span
              >
            </span>
            <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <font-awesome-icon v-if="open" icon="fa-solid fa-angle-up" class="h-5 w-5 text-gray-400" />
              <font-awesome-icon v-else icon="fa-solid fa-angle-down" class="h-5 w-5 text-gray-400" />
            </span>
          </ListboxButton>
          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm md:text-base"
            >
              <ListboxOption
                as="template"
                v-for="selection in selectionsOptions"
                :key="selection.id"
                :value="selection"
                v-slot="{ active, selected }"
              >
                <li
                  :class="[
                    active ? 'text-white bg-primary' : 'text-gray-900',
                    'relative cursor-default select-none py-2 pl-3 pr-9'
                  ]"
                >
                  <div class="flex items-center">
                    <font-awesome-icon
                      :icon="selection.tagKey"
                      class="w-5 h-5 rounded-full flex items-center justify-center ml-3"
                      :style="[{ color: selection.color }]"
                    />
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{
                      selection.name
                    }}</span>
                  </div>

                  <span
                    v-if="selected"
                    :class="[
                      active ? 'text-white' : 'text-primary',
                      'absolute inset-y-0 right-0 flex items-center pr-4'
                    ]"
                  >
                    <font-awesome-icon icon="fa-solid fa-check" class="h-5 w-5" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <!-- Reset filters button -->
      <div v-if="hasFilters" class="flex w-full items-center justify-center mt-5">
        <div>
          <Button
            @clicked="resetFilters"
            class="flex flex-row justify-center items-center text-white bg-secondary rounded-3xl px-4 py-2 hover:bg-opacity-80"
          >
            <font-awesome-icon icon="fa-solid fa-arrows-rotate" />
            <span class="ml-2">Supprimer les filtres</span>
          </Button>
        </div>
      </div>
    </div>
    <!-- Wines section -->
    <div class="flex flex-col bg-white rounded-sm p-3">
      <div class="flex flex-col md:flex-row mb-4 justify-between">
        <!-- Number of results -->
        <div class="flex flex-row items-center">
          <p class="text-primary text-lg font-semibold">
            {{ total }}
          </p>
          <p class="text-gray-400 text-lg font-medium ml-1">résultat{{ total === 1 ? '' : 's' }}</p>
        </div>
        <!-- Pagination -->
        <div v-show="areWinesLoading || (wines && wines.length > 0)" class="flex flex-row items-center mt-2 md:mt-0">
          <p class="text-gray-400 text-lg font-medium">Page</p>
          <p class="text-primary text-lg font-semibold ml-1">
            {{ page }}
          </p>
          <p class="text-gray-400 text-lg font-medium ml-1" v-if="Number.isInteger(pageMax)">sur {{ pageMax }}</p>
          <button @click="previousPage">
            <font-awesome-icon
              icon="fa-solid fa-chevron-left"
              class="fa-xl ml-2 text-secondary p-1 rounded-lg hover:bg-secondary hover:bg-opacity-60 hover:text-white"
            />
          </button>
          <button @click="nextPage">
            <font-awesome-icon
              icon="fa-solid fa-chevron-right"
              class="fa-xl text-secondary p-1 rounded-lg hover:bg-secondary hover:bg-opacity-60 hover:text-white"
            />
          </button>
        </div>
      </div>
      <!-- Loading state -->
      <div v-if="areWinesLoading" class="self-center w-3/4 xl:w-1/2">
        <p class="text-gray-400 text-lg font-medium text-center">Recherche en cours...</p>
        <div class="flex justify-center">
          <svg
            class="motion-reduce:hidden animate-spin h-20 w-20 text-primary my-10"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      </div>
      <!-- Empty state -->
      <div v-else-if="!areWinesLoading && (!wines || wines.length === 0)" class="self-center w-3/4 xl:w-1/2">
        <p class="text-gray-400 text-lg font-medium text-center">
          Oh non ! Pas de résultats pour votre recherche. Mais ne vous inquiétez pas, essayez de modifier vos critères
          ou jetez un coup d'œil à nos sélections de vins populaires pour trouver votre prochain coup de coeur.
        </p>
      </div>
      <!-- Wines list -->
      <!-- TODO : see if we should remove the `|| true` below, having it prevents the wines list blinking when changing filters in the search  -->
      <div v-else-if="!areWinesLoading || true" class="flex flex-row flex-wrap justify-around">
        <!-- Wine card -->
        <router-link
          class="group rounded-md shadow-md mb-6 mx-1 h-full bg-gray-50 hover:bg-gray-100"
          :style="{ width: '450px' }"
          v-for="wine in wines"
          :key="`wine_${wine.w_id}`"
          :to="`/wines/${wine.w_id}`"
        >
          <div class="flex flex-row h-full">
            <!-- Wine image -->
            <div class="rounded-l-md overflow-hidden w-44">
              <img
                v-show="winesImagesLoaded[wine.id]"
                @load="() => setWinesImagesLoaded(wine.id)"
                alt="Image du vin"
                :src="wine.w_img_path"
                class="object-cover object-center h-60 group-hover:scale-110 transition duration-300"
              />
              <div v-show="!winesImagesLoaded[wine.id]" class="bg-gray-200 flex items-center justify-center h-60">
                <font-awesome-icon icon="fa-solid fa-wine-bottle" class="text-gray-300 fa-6x" />
              </div>
            </div>
            <!-- Wine informations -->
            <div class="flex flex-col justify-between p-4 w-full">
              <div class="space-y-2">
                <!-- Wine title and tags -->
                <div class="w-full">
                  <div class="flex flex-row justify-between">
                    <div class="flex flex-row">
                      <p class="text-gray-800 text-2xl font-semibold mb-1">
                        {{ wine.w_vintage }} {{ wine.w_vintage_year }}
                      </p>
                      <div class="ml-3 mt-3 w-auto">
                        <Flag :country="wine?.Domain?.Pays" />
                      </div>
                    </div>
                    <!-- Wine price -->
                    <div class="shrink-0 self-start ml-1 mt-1">
                      <p class="text-gray-800 text-l font-semibold">{{ wine.w_public_price }} €</p>
                    </div>
                  </div>
                  <div class="flex items-center">
                    <p class="text-gray-700 text-base font-normal line-clamp-1">
                      {{
                        `${wine.w_grape_variety_1}${wine.w_grape_variety_2 ? ', ' + wine.w_grape_variety_2 : ''}${
                          wine.w_grape_variety_3 ? ', ' + wine.w_grape_variety_3 : ''
                        }${wine.w_grape_variety_4 ? ', ' + wine.w_grape_variety_4 : ''}${
                          wine.w_grape_variety_5 ? ', ' + wine.w_grape_variety_5 : ''
                        }`
                      }}
                    </p>
                  </div>
                </div>
                <!-- Wine description -->
                <div class="text-gray-700 text-base font-normal">
                  <!-- TODO : remove temporary placeholder description and true condition below when db completed -->
                  <p v-if="wine.w_description || true" class="line-clamp-4">
                    {{ wine.w_description || '' }}
                  </p>
                </div>
              </div>
              <div class="flex flex-row justify-end">
                <Div
                  class="w-10 h-10 rounded-full flex items-center justify-center ml-3"
                  :style="[{ 'background-color': `${icon.color}54` }]"
                  :tooltipText="icon.name"
                  v-for="icon in wine.WineTags"
                  :key="icon.id"
                >
                  <font-awesome-icon :icon="icon.tagKey" class="fa-lg" :style="[{ color: icon.color }]" />
                </Div>
              </div>
            </div>
          </div>
        </router-link>
        <div class="mx-1" :style="{ width: '450px' }"></div>
        <div class="mx-1" :style="{ width: '450px' }"></div>
        <div class="mx-1" :style="{ width: '450px' }"></div>
        <div class="mx-1" :style="{ width: '450px' }"></div>
        <div class="mx-1" :style="{ width: '450px' }"></div>
        <div class="mx-1" :style="{ width: '450px' }"></div>
      </div>
      <!-- Pagination -->
      <div
        v-show="!areWinesLoading && wines && wines.length > 0"
        class="flex flex-row items-center mt-2 md:mt-0 self-end"
      >
        <p class="text-gray-400 text-lg font-medium">Page</p>
        <p class="text-primary text-lg font-semibold ml-1">
          {{ page }}
        </p>
        <p class="text-gray-400 text-lg font-medium ml-1" v-if="Number.isInteger(pageMax)">sur {{ pageMax }}</p>
        <button @click="previousPage">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            class="fa-xl ml-2 text-secondary p-1 rounded-lg hover:bg-secondary hover:bg-opacity-60 hover:text-white"
          />
        </button>
        <button @click="nextPage">
          <font-awesome-icon
            icon="fa-solid fa-chevron-right"
            class="fa-xl text-secondary p-1 rounded-lg hover:bg-secondary hover:bg-opacity-60 hover:text-white"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import Button from '@/components/Button'
import { ref, onMounted, watch, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import winesApi from '@/api/wines'
import { Listbox, ListboxButton, ListboxOptions, ListboxOption, ListboxLabel } from '@headlessui/vue'
import { priceRanges, categories, countries, selections } from '@/const/mappings'
import debounce from '@/helpers/debounce'
import { useToastStore } from '@/stores/toast'
import Flag from '@/components/Flag'
import Div from '@/components/Div'
import DropdownFilter from '@/components/DropdownFilter'

const router = useRouter()
const route = useRoute()
const toastStore = useToastStore()
const categoriesOptions = Object.entries(categories).map(([key, value]) => ({
  id: key,
  name: value.name,
  color: value.color
}))
const countriesOptions = Object.entries(countries).map(([key, value]) => ({
  id: key,
  name: value
}))
const priceRangesOptions = Object.entries(priceRanges).map(([key, value]) => ({
  id: key,
  name: value
}))
const selectionsOptions = Object.entries(selections).map(([key, value]) => ({
  id: key,
  name: value.name,
  color: value.color,
  tagKey: value.tagKey
}))

const areWinesLoading = ref(true)
const winesImagesLoaded = ref({})
const wines = ref([])

const page = ref(1)
const pageMax = ref(1)
const total = ref(0)
const priceRangesSelected = ref([])
const categoriesSelected = ref([])
const countriesSelected = ref([])
const selectionsSelected = ref([])
const search = ref('')

const hasFilters = computed(() => {
  return (
    priceRangesSelected.value.length > 0 ||
    categoriesSelected.value.length > 0 ||
    countriesSelected.value.length > 0 ||
    selectionsSelected.value.length > 0 ||
    search.value.length > 0
  )
})

watch(page, () => {
  computeQuery()
})
watch(priceRangesSelected, () => {
  computeQuery()
})
watch(categoriesSelected, () => {
  computeQuery()
})
watch(countriesSelected, () => {
  computeQuery()
})
watch(selectionsSelected, () => {
  computeQuery()
})
watch(search, () => {
  computeQuery()
})

onMounted(async () => {
  loadSearchFromQuery()
  await computeQuery()
})

function loadSearchFromQuery() {
  if (route?.query?.page) {
    page.value = Math.max(route?.query?.page, 1)
  }
  if (route?.query?.priceRangesSelected) {
    const queryPriceRangesIds = route?.query?.priceRangesSelected?.split(',')
    priceRangesSelected.value = priceRangesOptions.filter(option => queryPriceRangesIds.includes(option.id))
  }
  if (route?.query?.categoriesSelected) {
    const queryCategoriesIds = route?.query?.categoriesSelected?.split(',')
    categoriesSelected.value = categoriesOptions.filter(option => queryCategoriesIds.includes(option.id))
  }
  if (route?.query?.countriesSelected) {
    const queryCountriesIds = route?.query?.countriesSelected?.split(',')
    countriesSelected.value = countriesOptions.filter(option => queryCountriesIds.includes(option.id))
  }
  if (route?.query?.selectionsSelected) {
    const querySelectionsIds = route?.query?.selectionsSelected?.split(',')
    selectionsSelected.value = selectionsOptions.filter(option => querySelectionsIds.includes(option.id))
  }
  if (route?.query?.search) {
    search.value = route?.query?.search
  }
}

function previousPage() {
  if (page.value <= 1) return
  page.value--
  computeQuery()
  scrollToTop()
}

function nextPage() {
  if (page.value >= pageMax.value) return
  page.value++
  computeQuery()
  scrollToTop()
}

async function getWines() {
  areWinesLoading.value = true
  try {
    const res = await winesApi.getWines({
      page: page.value ? page.value : undefined,
      priceRanges: priceRangesSelected.value.map(priceRange => priceRange.id).join(',') || undefined,
      categories: categoriesSelected.value.map(category => category.id).join(',') || undefined,
      countries: countriesSelected.value.map(country => country.name).join(',') || undefined,
      selections: selectionsSelected.value.map(selection => selection.id).join(',') || undefined,
      search: search.value || undefined
    })
    wines.value = res?.data?.wines || []
    page.value = res?.data?.page || 1
    pageMax.value = res?.data?.pageMax || 1
    total.value = res?.data?.total || 0
  } catch (err) {
    console.error(err)
    toastStore.showToast({
      error: true,
      title: 'Erreur',
      message: err.response.data.message
    })
  }
  areWinesLoading.value = false
}

const getWinesDebounced = debounce(getWines, 500)

async function computeQuery() {
  router.push({
    query: {
      ...route.query,
      page: page.value,
      priceRangesSelected:
        priceRangesSelected.value.length > 0
          ? priceRangesSelected.value.map(priceRange => priceRange.id).join(',')
          : undefined,
      categoriesSelected:
        categoriesSelected.value.length > 0
          ? categoriesSelected.value.map(category => category.id).join(',')
          : undefined,
      countriesSelected:
        countriesSelected.value.length > 0 ? countriesSelected.value.map(country => country.id).join(',') : undefined,
      selectionsSelected:
        selectionsSelected.value.length > 0
          ? selectionsSelected.value.map(selection => selection.id).join(',')
          : undefined,
      search: search.value.trim() || undefined
    }
  })
  await getWinesDebounced()
}

function resetFilters() {
  priceRangesSelected.value = []
  categoriesSelected.value = []
  countriesSelected.value = []
  selectionsSelected.value = []
  search.value = ''
  computeQuery()
}

function setWinesImagesLoaded(wineId) {
  winesImagesLoaded.value[wineId] = true
}

function scrollToTop() {
  window.scrollTo(0, 0)
}
</script>
