<template>
  <div class="h-max md:my-16 md:mx-auto lg:w-1/2 md:w-2/3 m-4 flex flex-col">
    <div class="space-y-10 divide-y divide-gray-900/10">
      <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10">
        <div class="px-4 sm:px-0">
          <h2 class="text-base font-semibold leading-7 text-gray-900">Informations Personnelles</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
            Vos informations personnelles ainsi que votre adresse de facturation
          </p>
        </div>

        <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Prénom</label>
                <div class="mt-2">
                  <input
                    v-model="personalInfo.firstName"
                    type="text"
                    name="first-name"
                    id="first-name"
                    autocomplete="given-name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Nom</label>
                <div class="mt-2">
                  <input
                    v-model="personalInfo.lastName"
                    type="text"
                    name="last-name"
                    id="last-name"
                    autocomplete="family-name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label for="phone-number" class="block text-sm font-medium leading-6 text-gray-900"
                  >Numéro de Téléphone</label
                >
                <div class="mt-2">
                  <TextField
                    v-model:phone="phone"
                    v-model:phoneObj="phoneObj"
                    id="phone_number"
                    type="tel"
                    :isColorLight="true"
                    class=""
                  ></TextField>
                </div>
              </div>
              <div class="sm:col-span-2">
                <label for="birthday" class="block text-sm font-medium leading-6 text-gray-900"
                  >Date de naissance</label
                >
                <div class="mt-2">
                  <TextField
                    v-model="personalInfo.birthDate"
                    type="date"
                    name="birthday"
                    id="birthday"
                    autocomplete="birthday"
                    class=""
                  ></TextField>
                </div>
              </div>

              <div class="sm:col-span-4">
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Adresse Email</label>
                <div class="mt-2">
                  <TextField
                    v-model="personalInfo.email"
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    :isDisabled="true"
                    class=""
                  ></TextField>
                </div>
              </div>

              <div class="sm:col-span-4">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Pays</label>
                <div class="mt-2">
                  <select
                    v-model="personalInfo.country"
                    id="country"
                    name="country"
                    autocomplete="country-name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option>Angleterre</option>
                    <option>Belgique</option>
                    <option>Espagne</option>
                    <option>France</option>
                    <option>Portugal</option>
                  </select>
                </div>
              </div>

              <div class="col-span-full">
                <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Adresse</label>
                <div class="mt-2">
                  <input
                    v-model="personalInfo.address"
                    type="text"
                    name="street-address"
                    id="street-address"
                    autocomplete="street-address"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-4 sm:col-start-1">
                <label for="city" class="block text-sm font-medium leading-6 text-gray-900">Ville</label>
                <div class="mt-2">
                  <input
                    v-model="personalInfo.city"
                    type="text"
                    name="city"
                    id="city"
                    autocomplete="address-level2"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="postal-code" class="block text-sm font-medium leading-6 text-gray-900">Code Postal</label>
                <div class="mt-2">
                  <input
                    v-model="personalInfo.postalCode"
                    type="text"
                    name="postal-code"
                    id="postal-code"
                    autocomplete="postal-code"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <Button
              @click="validatePersonalInfo"
              :loading="isLoadingPersonalInfo"
              type="submit"
              class="w-auto rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              Enregistrer
            </Button>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10">
        <div class="px-4 sm:px-0">
          <h2 class="text-base font-semibold leading-7 text-gray-900">Adresse de livraison</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
            Dans le cas d'un abonnement ou d'une commande boutique livrée à <b>domicile</b>.
          </p>
        </div>

        <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Prénom</label>
                <div class="mt-2">
                  <input
                    v-model="selectedHomeDelivery.deliveryFirstName"
                    type="text"
                    name="first-name"
                    id="first-name"
                    autocomplete="given-name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Nom</label>
                <div class="mt-2">
                  <input
                    v-model="selectedHomeDelivery.deliveryLastName"
                    type="text"
                    name="last-name"
                    id="last-name"
                    autocomplete="family-name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-4">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Pays</label>
                <div class="mt-2">
                  <select
                    v-model="selectedHomeDelivery.deliveryCountry"
                    id="country"
                    name="country"
                    autocomplete="country-name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option>Angleterre</option>
                    <option>Belgique</option>
                    <option>Espagne</option>
                    <option>France</option>
                    <option>Portugal</option>
                  </select>
                </div>
              </div>

              <div class="col-span-full">
                <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900">Adresse</label>
                <div class="mt-2">
                  <input
                    v-model="selectedHomeDelivery.deliveryAddress"
                    type="text"
                    name="street-address"
                    id="street-address"
                    autocomplete="street-address"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-4 sm:col-start-1">
                <label for="city" class="block text-sm font-medium leading-6 text-gray-900">Ville</label>
                <div class="mt-2">
                  <input
                    v-model="selectedHomeDelivery.deliveryCity"
                    type="text"
                    name="city"
                    id="city"
                    autocomplete="address-level2"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="postal-code" class="block text-sm font-medium leading-6 text-gray-900">Code Postal</label>
                <div class="mt-2">
                  <input
                    v-model="selectedHomeDelivery.deliveryPostalCode"
                    type="text"
                    name="postal-code"
                    id="postal-code"
                    autocomplete="postal-code"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <Button
              @click="validateHomeDelivery"
              :loading="isLoadingHomeDelivery"
              class="w-auto rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              Enregistrer
            </Button>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10">
        <div class="px-4 sm:px-0">
          <h2 class="text-base font-semibold leading-7 text-gray-900">Point Relais</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
            Dans le cas d'un abonnement ou d'une commande boutique livrée en <b>Point Relais</b>.
          </p>
        </div>

        <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-5">
                <label for="relay-name" class="block text-sm font-medium leading-6 text-gray-900">Nom</label>
                <div class="mt-2">
                  <input
                    v-model="selectedRelayPoint.relayPointName"
                    type="text"
                    name="relay-name"
                    id="relay-name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    disabled
                  />
                </div>
              </div>

              <div class="sm:col-span-4">
                <label for="relay-country" class="block text-sm font-medium leading-6 text-gray-900">Pays</label>
                <div class="mt-2">
                  <input
                    v-model="selectedRelayPoint.relayPointAddress"
                    type="text"
                    name="relay-country"
                    id="relay-country"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    disabled
                  />
                </div>
              </div>

              <div class="col-span-full">
                <label for="relay-street-address" class="block text-sm font-medium leading-6 text-gray-900"
                  >Adresse</label
                >
                <div class="mt-2">
                  <input
                    v-model="selectedRelayPoint.relayPointCountry"
                    type="text"
                    name="relay-street-address"
                    id="relay-street-address"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-100 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="isSelectingRelayPoint">
            <div class="flex flex-col w-full">
              <div id="Zone_Widget"></div>
            </div>
          </div>
          <div class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <button
              v-if="isSelectingRelayPoint"
              @click="toggleRelayPoint"
              type="button"
              class="text-sm font-semibold leading-6 text-gray-900 hover:text-opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              Annuler
            </button>
            <Button
              v-if="isSelectingRelayPoint"
              :loading="isLoadingRelayPoint"
              @click="validateRelayPoint"
              class="w-auto rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              Confirmer
            </Button>
            <Button
              v-if="!isSelectingRelayPoint"
              @click="toggleRelayPoint"
              class="w-auto rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              Changer
            </Button>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10">
        <div class="px-4 sm:px-0">
          <h2 class="text-base font-semibold leading-7 text-gray-900">Notifications</h2>
        </div>

        <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div class="px-4 py-6 sm:p-8">
            <div class="relative flex gap-x-3">
              <div class="flex h-6 items-center">
                <input
                  v-model="personalInfo.newslettersApproved"
                  id="comments"
                  name="comments"
                  type="checkbox"
                  class="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                />
              </div>
              <div class="text-sm leading-6">
                <label for="comments" class="font-medium text-gray-900">Newsletter</label>
                <p class="text-gray-500">Cochez la case afin de recevoir les newsletters La Chaussette ©.</p>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <Button
              @click="validateNewsletter"
              :loading="isLoadingNewsletter"
              class="w-auto rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            >
              Enregistrer
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useOrderStore } from '@/stores/subscriptionOrder'
import { useAppStateStore } from '@/stores/appState'
import { useToastStore } from '@/stores/toast'
import clientsApi from '@/api/clients'

import countryHelpers from '@/helpers/country'
import Button from '@/components/Button'
import TextField from '@/components/TextField'

export default {
  name: 'MyAccount',
  components: {
    Button,
    TextField
  },
  data() {
    return {
      personalInfo: {},
      phone: null,
      phoneObj: {},
      selectedRelayPoint: {},
      selectedHomeDelivery: {},
      isLoadingPersonalInfo: false,
      isLoadingHomeDelivery: false,
      isSelectingRelayPoint: false,
      isLoadingRelayPoint: false,
      isLoadingNewsletter: false
    }
  },
  mounted() {
    this.personalInfo = {
      firstName: this.user.c_first_name,
      lastName: this.user.c_last_name,
      birthDate: this.user.c_birth_date,
      email: this.user.c_email,
      address: this.user.c_address,
      city: this.user.c_city,
      postalCode: this.user.c_zip_code,
      country: this.user.c_country,
      newslettersApproved: this.user.c_newsletters_approved
    }

    this.phone =
      this.user.c_phone_indicative && this.user.c_phone_number
        ? `+${this.user.c_phone_indicative}${this.user.c_phone_number}`
        : null

    this.selectedHomeDelivery = {
      deliveryFirstName: this.user.c_delivery_first_name || this.user.c_first_name,
      deliveryLastName: this.user.c_delivery_last_name || this.user.c_last_name,
      deliveryAddress: this.user.c_delivery_address,
      deliveryCity: this.user.c_delivery_city || this.user.c_city,
      deliveryPostalCode: this.user.c_delivery_zip_code || this.user.c_zip_code,
      deliveryCountry: this.user.c_delivery_country
    }

    if (this.hasRelayPoint) {
      this.selectedRelayPoint = {
        relayPointId: this.user.c_relay_point_id,
        relayPointName: this.user.c_relay_point_name,
        relayPointAddress: this.user.c_relay_point_address,
        relayPointCountry: this.user.c_relay_point_country
      }
    } else {
      document.default_relay_point_country = countryHelpers.getCountryFromName(this.user.c_country).id
      document.default_relay_point_city = this.user.c_city
      document.default_relay_point_zipcode = this.user.c_zip_code
    }
  },
  computed: {
    ...mapState(useOrderStore, {
      order: state => state
    }),
    ...mapState(useAppStateStore, {
      user: state => state.user
    }),
    hasRelayPoint() {
      return this.user.c_relay_point_id && this.user.c_relay_point_id !== ''
    }
  },
  methods: {
    ...mapActions(useToastStore, ['showToast']),
    ...mapActions(useAppStateStore, ['updateUser']),
    ...mapActions(useOrderStore, ['updateOrder']),
    async validatePersonalInfo() {
      this.isLoadingPersonalInfo = true

      if (!this.phoneObj.countryCallingCode || !this.phoneObj.nationalNumber) {
        this.isLoadingPersonalInfo = false
        this.showToast({
          error: true,
          title: 'Erreur',
          message: 'Le numéro de téléphone que vous avez renseigné est invalide'
        })
        return
      }

      if (
        !this.personalInfo.firstName ||
        !this.personalInfo.lastName ||
        !this.personalInfo.email ||
        !this.personalInfo.address ||
        !this.personalInfo.city ||
        !this.personalInfo.postalCode ||
        !this.personalInfo.country
      ) {
        this.isLoadingPersonalInfo = false
        this.showToast({
          error: true,
          title: 'Erreur',
          message: 'Veuillez remplir les informations obligatoires'
        })
        return
      }

      const newUserInfo = {
        ...this.personalInfo,
        ...this.selectedHomeDelivery,
        ...this.selectedRelayPoint,
        email: this.user.c_email,
        phoneCountryIndicator: this.phoneObj.countryCallingCode,
        phoneNumber: this.phoneObj.nationalNumber
      }
      const res = await clientsApi.updateUser(newUserInfo)
      const user = res?.data

      if (user) {
        this.updateUser(user)

        this.showToast({
          success: true,
          title: 'Succès',
          message: 'Vos informations personnelles ont bien été mises à jour'
        })
      } else {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: 'Une erreur est survenue pendant la mise à jour de vos informations personnelles'
        })
      }
      this.isLoadingPersonalInfo = false
    },
    async validateHomeDelivery() {
      this.isLoadingHomeDelivery = true
      if (
        !this.selectedHomeDelivery.deliveryFirstName ||
        !this.selectedHomeDelivery.deliveryLastName ||
        !this.selectedHomeDelivery.deliveryAddress ||
        !this.selectedHomeDelivery.deliveryCity ||
        !this.selectedHomeDelivery.deliveryPostalCode ||
        !this.selectedHomeDelivery.deliveryCountry
      ) {
        this.isLoadingHomeDelivery = false
        this.showToast({
          error: true,
          title: 'Erreur',
          message: 'Veuillez renseigner toutes les informations de livraison'
        })
        return
      }

      const newUserInfo = {
        ...this.personalInfo,
        ...this.selectedHomeDelivery,
        ...this.selectedRelayPoint
      }
      const res = await clientsApi.updateUser(newUserInfo)
      const user = res?.data
      if (user) {
        this.updateUser(user)
        this.showToast({
          success: true,
          title: 'Succès',
          message: 'Votre adresse de livraison a bien été mises à jour'
        })
      } else {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: 'Une erreur est survenue pendant la mise à jour de votre adresse de livraison'
        })
      }
      this.isLoadingHomeDelivery = false
    },
    toggleRelayPoint() {
      this.isSelectingRelayPoint = !this.isSelectingRelayPoint
    },
    async validateRelayPoint() {
      this.isLoadingRelayPoint = true
      if (
        !document.relay_point_id ||
        !document.relay_point_name ||
        !document.relay_point_address ||
        !document.relay_point_country
      ) {
        this.isLoadingRelayPoint = false
        this.showToast({
          error: true,
          title: 'Erreur',
          message: 'Veuillez sélectionner un point relais'
        })
        return
      }

      this.selectedRelayPoint = {
        relayPointId: document.relay_point_id,
        relayPointName: document.relay_point_name,
        relayPointAddress: document.relay_point_address,
        relayPointCountry: countryHelpers.getCountryFromId(document.relay_point_country).name
      }

      const newUserInfo = {
        ...this.personalInfo,
        ...this.selectedHomeDelivery,
        ...this.selectedRelayPoint
      }
      const res = await clientsApi.updateUser(newUserInfo)
      const user = res?.data
      if (user) {
        this.updateUser(user)
        this.showToast({
          success: true,
          title: 'Succès',
          message: 'Votre point relais a bien été mis à jour'
        })
        this.toggleRelayPoint()
      } else {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: 'Une erreur est survenue pendant la mise à jour de votre point relais'
        })
      }
      this.isLoadingRelayPoint = false
    },
    async validateNewsletter() {
      this.isLoadingNewsletter = true

      const newUserInfo = {
        ...this.personalInfo,
        ...this.selectedHomeDelivery,
        ...this.selectedRelayPoint,
        newslettersApproved: this.personalInfo.newslettersApproved
      }
      const res = await clientsApi.updateUser(newUserInfo)
      const user = res?.data

      if (user) {
        this.updateUser(user)
        // TODO: test that it correctly save in front and back the new 'newslettersApproved' choice

        this.showToast({
          success: true,
          title: 'Succès',
          message: 'Vos préférences de newsletter ont bien été mises à jour'
        })
      } else {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: 'Une erreur est survenue pendant la mise à jour de vos préférences de newsletter'
        })
      }
      this.isLoadingNewsletter = false
    }
  }
}
</script>

<style src="vue3-tel-input/dist/vue3-tel-input.css"></style>
