// Param : 'search'

// Param : 'price-ranges'
const priceRanges = {
  'less-than-ten': "Jusqu'à 10 €",
  'ten-to-twenty': '10 à 20 €',
  'twenty-to-thirty': '20 à 30 €',
  'more-than-thirty': 'Plus de 30 €'
}

// Param : 'categories'
const categories = {
  white: { name: 'Blanc', color: 'bg-[#FFD979]' },
  red: { name: 'Rouge', color: 'bg-[#B11226]' },
  // rose: 'Rosé',
  // sparkling: 'Effervescent',
  liquor: { name: 'Spiritueux', color: 'bg-[#6495ED]' },
  goodies: { name: 'Autres', color: 'bg-[#000000]' }
}

// Param : 'countries'
const countries = {
  fr: 'France',
  es: 'Espagne',
  it: 'Italie',
  at: 'Autriche'
}

// Param : 'selections'
const selections = {
  currentSelection: { name: 'Vins du moment', color: '#FF9529', tagKey: 'fa-star fa-solid' },
  favorites: { name: 'Coups de coeur', color: '#f87171', tagKey: 'heart' },
  bio: { name: 'Bio', color: '#1FC71F', tagKey: 'fa-earth-americas fa-solid' },
  nature: { name: 'Nature', color: '#D1BB91', tagKey: 'fa-paw fa-solid' },
  // spring: { name: 'Printemps', color: '#49A135', tagKey: 'fa-leaf fa-solid' },
  // summer: { name: 'Été', color: '#FFCB00', tagKey: 'fa-sun fa-solid' },
  // autumn: { name: 'Automne', color: '#F47B20', tagKey: 'fa-canadian-maple-leaf fa-brands' },
  // winter: { name: 'Hiver', color: '#95B6FD', tagKey: 'fa-snowflake fa-solid' }
}

module.exports = {
  priceRanges,
  categories,
  countries,
  selections
}
