<template>
  <div class="relative w-60">
    <label
      :for="id"
      class="block text-sm font-medium"
      :class="[isColorLight ? 'text-white' : 'text-gray-700', isDisabled ? ' text-gray-700' : '']"
    >
      {{ label }}
      <span v-if="isRequired" class="ml-0.5 text-red-500 font-bold">*</span>
    </label>
    <div class="mt-1">
      <input
        @input="$emit('update:modelValue', $event.target.value)"
        v-if="type !== 'tel'"
        :type="type"
        :name="id"
        :id="id"
        :disabled="isDisabled"
        :value="modelValue"
        class="peer text-gray-600 shadow-sm block w-full sm:text-sm rounded-md focus:ring-secondary focus:border-secondary invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
        :class="isDisabled ? 'bg-gray-300 text-gray-600 border-gray-500 shadow-none' : 'bg-gray-50'"
      />

      <!-- Phone number type only -->
      <vue-tel-input
        @input="setPhoneValue"
        v-else
        :value="phone"
        mode="international"
        defaultCountry="FR"
        :autoDefaultCountry="false"
        :inputOptions="{ placeholder: '', name: id, id: id }"
        styleClasses="text-gray-600 bg-white rounded-md"
      >
      </vue-tel-input>
    </div>
    <p
      v-if="helpText"
      :id="id + '-help'"
      class="mt-2 text-sm italic"
      :class="isColorLight ? 'text-white' : 'text-gray-500'"
    >
      {{ helpText }}
    </p>
    <p v-if="invalidText" class="mt-2 invisible peer-invalid:visible text-pink-600 text-sm">{{ invalidText }}</p>
  </div>
</template>

<script>
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

export default {
  props: {
    type: {
      type: String
    },
    id: {
      type: String
    },
    label: {
      type: String
    },
    helpText: {
      type: String
    },
    invalidText: {
      type: String
    },
    isRequired: {
      type: Boolean
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isColorLight: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String
    },
    phone: {
      type: String
    },
    phoneObj: {
      type: Object
    }
  },
  components: {
    VueTelInput
  },
  methods: {
    setPhoneValue(phone, phoneObject, input) {
      if (phoneObject) {
        this.$emit('update:phone', phoneObject.formatted)
        this.$emit('update:phoneObj', phoneObject)
      }
    }
  }
}
</script>

<style scoped></style>
