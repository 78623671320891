const subscriptionType = {
  OneTime: 'payment',
  Subscription: 'subscription'
}

const boxType = {
  classic: 1,
  prestige: 2
}

// Moment returns month number from 0 to 11
const prestigeShipping = [1, 4, 7, 10] // Les boxes prestiges sont envoyées en Février, Mai, Août et Novembre

// /!\ This is duplicated in the backend
const departmentsIDF = ['75', '77', '78', '91', '92', '93', '94', '95']

const allSubscriptionOptions = {
  gift: [
    {
      id: 'giftOneTimeFourMonths',
      title: 'Le curieux',
      subtitle: 'Un cadeau parfait pour ceux qui souhaitent apprendre les bases de la dégustation !',
      description: 'Reçu tous les mois pendant 4 mois',
      subscription: 'Abonnement 4 mois',
      price: 11600,
      subscriptionType: subscriptionType.OneTime,
      monthsToPay: 4,
      nbMonthsDuration: 4,
      color: 'primary',
      boxType: boxType.classic,
      deliveryFrequency: 'mois',
      paymentFrequency: undefined
    },
    {
      id: 'giftOneTimeSixMonths',
      title: "L'Explorateur",
      subtitle: 'Le cadeau sans faille qui fera son effet !',
      description: 'Reçu tous les mois pendant 6 mois',
      subscription: 'Abonnement 6 mois',
      price: 17400,
      subscriptionType: subscriptionType.OneTime,
      monthsToPay: 6,
      nbMonthsDuration: 6,
      color: 'primary',
      boxType: boxType.classic,
      deliveryFrequency: 'mois',
      paymentFrequency: undefined
    },
    {
      id: 'giftOneTimeOneYear',
      title: 'Le dégustateur',
      subtitle: "Du vin et de l'amour, que demander de plus ?",
      description: 'Reçu tous les mois pendant 12 mois',
      subscription: 'Abonnement 12 mois',
      price: 34800,
      subscriptionType: subscriptionType.OneTime,
      monthsToPay: 12,
      nbMonthsDuration: 12,
      color: 'primary',
      boxType: boxType.classic,
      deliveryFrequency: 'mois',
      paymentFrequency: undefined
    }
  ],
  subscription: [
    {
      id: 'selfOneTimeFourMonths',
      title: 'Le curieux',
      subtitle: "L'abonnement parfait pour une aventure découverte",
      description: 'Reçu tous les mois pendant 4 mois',
      subscription: 'Abonnement 4 mois',
      price: 11600,
      subscriptionType: subscriptionType.OneTime,
      monthsToPay: 4,
      nbMonthsDuration: 4,
      color: 'primary',
      boxType: boxType.classic,
      deliveryFrequency: 'mois',
      paymentFrequency: undefined
    },
    {
      id: 'selfSubscription',
      title: 'Le classique',
      subtitle: 'Engagement 4 mois minimum puis arrêt sans frais, par simple mail',
      description: 'Reçu tous les mois',
      subscription: 'Abonnement MENSUEL',
      price: 2900,
      subscriptionType: subscriptionType.Subscription,
      monthsToPay: 1,
      color: 'primary',
      boxType: boxType.classic,
      deliveryFrequency: 'mois',
      paymentFrequency: 'mois'
    },
    {
      id: 'selfSubscriptionPrestige',
      title: 'Le prestige',
      subtitle: 'Engagement 1 an minimum puis arrêt sans frais, par simple mail',
      description: 'Reçu tous les trimestres pendant 1 an',
      subscription: 'Abonnement TRIMESTRIEL',
      price: 6000,
      subscriptionType: subscriptionType.Subscription,
      monthsToPay: 1, // This attribute should be named 'nbDeliveries'
      color: 'golden',
      boxType: boxType.prestige,
      deliveryFrequency: 'trimestre',
      paymentFrequency: 'trimestre'
    }
  ]
}

const countries = [
  {
    id: 'FR',
    flag: '🇫🇷',
    name: 'France',
    pointRelayAvailable: true,
    pointRelayPrice: 0,
    homePrice: 600
  },
  {
    id: 'BE',
    flag: '🇧🇪',
    name: 'Belgique',
    pointRelayAvailable: true,
    pointRelayPrice: 0,
    homePrice: 600
  },
  {
    id: 'LU',
    flag: '🇱🇺',
    name: 'Luxembourg',
    pointRelayAvailable: true,
    pointRelayPrice: 0,
    homePrice: 600
  },
  {
    id: 'NL',
    flag: '🇳🇱',
    name: 'Pays-Bas',
    pointRelayAvailable: true,
    pointRelayPrice: 400,
    homePrice: 600
  },
  {
    id: 'ES',
    flag: '🇪🇸',
    name: 'Espagne',
    pointRelayAvailable: true,
    pointRelayPrice: 400,
    homePrice: 1100
  },
  {
    id: 'PT',
    flag: '🇵🇹',
    name: 'Portugal',
    pointRelayAvailable: true,
    pointRelayPrice: 400,
    homePrice: 1100
  },
  {
    id: 'DE',
    flag: '🇩🇪',
    name: 'Allemagne',
    pointRelayAvailable: false,
    pointRelayPrice: 0,
    homePrice: 600
  },
  {
    id: 'Ita',
    flag: '🇮🇹',
    name: 'Italie',
    pointRelayAvailable: false,
    pointRelayPrice: 0,
    homePrice: 1100
  },
  {
    id: 'AT',
    flag: '🇦🇹',
    name: 'Autriche',
    pointRelayAvailable: false,
    pointRelayPrice: 0,
    homePrice: 1100
  }
]

const deliveryOptions = [
  {
    id: 'relayPointDelivery',
    title: 'Livraison en point relais',
    getDescription: (deliveryFrequency = 'mois') => `Coffret reçu tous les ${deliveryFrequency}`,
    giftDescription: isGift =>
      isGift
        ? "Le bénéficiaire de l'abonnement pourra choisir l'adresse du Point Relais une fois que ce dernier l'aura activé"
        : "Vous pourrez choisir l'adresse du Point Relais à l'étape 3",
    isPointRelay: true
  },
  {
    id: 'homeDelivery',
    title: 'Livraison à domicile',
    getDescription: (deliveryFrequency = 'mois') => `Coffret reçu tous les ${deliveryFrequency}`,
    giftDescription: isGift =>
      isGift
        ? "Le bénéficiaire de l'abonnement pourra choisir l'adresse de livraison une fois que ce dernier l'aura activé"
        : "Vous pourrez choisir l'adresse de livraison à l'étape 3",
    isPointRelay: false
  }
]

const stripeIds = env =>
  env === 'production'
    ? {
        // Classique unlimited
        2900: 'price_1OG0iQCGnlixN1ieI7C83aF8', // 'price_1KkRm3CGnlixN1iexTcNlmk3'
        3300: 'price_1OG0jYCGnlixN1iecksEBrDV', // 'price_1KkRm3CGnlixN1ieP3xqcn7a'
        3500: 'price_1OG0k0CGnlixN1ieef9PmmzG', // 'price_1KkRm3CGnlixN1ieNe9yQUrp'
        4000: 'price_1OG0kQCGnlixN1iedElQ84rP', // 'price_1KkRm3CGnlixN1ieX6ktn0K3'
        // Prestige unlimited
        6000: 'price_1OG28pCGnlixN1iez8g1t7JA', // 'price_1KkRkRCGnlixN1ieq7LRZQlU'
        6400: 'price_1OG299CGnlixN1iemHWF5f4o', // 'price_1KkRkRCGnlixN1iebWq8reIK'
        6600: 'price_1OG29WCGnlixN1ier8txKNv0', // 'price_1KkRkRCGnlixN1ierLWxWKbW'
        7100: 'price_1OG29wCGnlixN1ie5aoA2VtW', // 'price_1KkRkRCGnlixN1ie6eQJQAG2'
        // Classique 4 months
        11600: 'price_1OG0lKCGnlixN1ieIQMAgC8f', // 'price_1KkRkFCGnlixN1ieodoTqfGR'
        13200: 'price_1OG0llCGnlixN1ieMZHq7znN', // 'price_1KkRkFCGnlixN1ieblmywI9Y'
        14000: 'price_1OG0mACGnlixN1ie6x1DIhd2', // 'price_1KkRkFCGnlixN1ieJK28fD3P'
        16000: 'price_1OG0mVCGnlixN1ieTZw1XKYi', // 'price_1KkRkFCGnlixN1ieINXtfDqQ'
        // Classique 6 months
        17400: 'price_1OG0nhCGnlixN1ieVcNfEryw', // 'price_1KkRkKCGnlixN1iedzirlY0g'
        19800: 'price_1OG0nzCGnlixN1iehDAaK1og', // 'price_1KkRkKCGnlixN1ieKIBOIDbT'
        21000: 'price_1OG0oOCGnlixN1ieADzR3x0c', // 'price_1KkRkKCGnlixN1ieBT1ACUKG'
        24000: 'price_1OG0oqCGnlixN1ietBHDICqU', // 'price_1KkRkKCGnlixN1ieYFnolYDp'
        // Classique 12 months
        34800: 'price_1OG26TCGnlixN1iedjpj5nOM', // 'price_1KkRkNCGnlixN1ieaygQIoAd'
        39600: 'price_1OG26tCGnlixN1ie8QzvDDk7', // 'price_1KkRkNCGnlixN1ieVNeTd5Yw'
        42000: 'price_1OG27ECGnlixN1ieDVM876U9', // 'price_1KkRkNCGnlixN1ieBqYIQsff'
        48000: 'price_1OG27nCGnlixN1ieEwDrcGWt' // 'price_1KkRkNCGnlixN1ieEA6hAj2q'
      }
    : {
        // Classique unlimited
        2900: 'price_1OFyAaCGnlixN1ierna7GSzt', // 'price_1HXSD8CGnlixN1ietYhYmSzs'
        3300: 'price_1OFyDYCGnlixN1ieHImJr5Wz', // 'price_1KKhPjCGnlixN1ie16j4vnSr'
        3500: 'price_1OFyFOCGnlixN1ievTPqINws', // 'price_1KKhRsCGnlixN1ieR0yS4ZGM'
        4000: 'price_1OG0XiCGnlixN1ieGaKwcTzV', // 'price_1KKhSxCGnlixN1ie6X0KonBv'
        // Prestige unlimited
        6000: 'price_1OFyV1CGnlixN1ieJyKgw2px', // 'price_1KkNmHCGnlixN1ieXBLU07G5'
        6400: 'price_1OFyVBCGnlixN1ieLvNZNCaz', // 'price_1KkNmHCGnlixN1iejrqwv2YG'
        6600: 'price_1OFyVJCGnlixN1ieP5X88SJ3', // 'price_1KkNmHCGnlixN1ie6dpjm5dM'
        7100: 'price_1OG0fgCGnlixN1ie9HboGf6U', // 'price_1KkNmHCGnlixN1ier3Fxv62l'
        // Classique 4 months
        11600: 'price_1OFyMjCGnlixN1ie9K7cADPv', // 'price_1KG6ftCGnlixN1ie44LC0UY5'
        13200: 'price_1OFyMpCGnlixN1ie3PJIxe60', // 'price_1KKhYYCGnlixN1ieApSUwOJn'
        14000: 'price_1OFyMxCGnlixN1ietn1AbMdg', // 'price_1KKhYJCGnlixN1iexn4lQsrV'
        16000: 'price_1OG0elCGnlixN1ieFWBXGTZd', // 'price_1KKhYfCGnlixN1ieI8JjZHke'
        // Classique 6 months
        17400: 'price_1OFyRICGnlixN1ieFqGyUo8s', // 'price_1KKhcGCGnlixN1ieuQ4itokY'
        19800: 'price_1OFyRRCGnlixN1ieJWGqiotw', // 'price_1KKhcGCGnlixN1ieO2QN7M4m'
        21000: 'price_1OFyRZCGnlixN1ieFDlcC3SD', // 'price_1KKhcGCGnlixN1ieCktMCEnq'
        24000: 'price_1OFyRfCGnlixN1ieNu9yLwHe', // 'price_1KKhcGCGnlixN1ie0YVCn7lB'
        // Classique 12 months
        34800: 'price_1OFyTfCGnlixN1ie6NF3TYyo', // 'price_1KKhd5CGnlixN1ie1Ju4lmB1'
        39600: 'price_1OFyTmCGnlixN1ieuV2YtvhL', // 'price_1KKhd5CGnlixN1iex4tfjMW1'
        42000: 'price_1OFyTzCGnlixN1ietdJ7iHmi', // 'price_1KKhd5CGnlixN1ieAjMf34sv'
        48000: 'price_1OFyU8CGnlixN1iegrygih9x' // 'price_1KKhd5CGnlixN1ieHf9tKcQo'
      }

// /!\ This is duplicated in the backend
const deliveryStripePriceIds = env =>
  env === 'production'
    ? {
        0: {
          w_id: 0,
          w_vintage: 'Livraison OFFERTE',
          w_stripe_price_id: 'price_1OHWXzCGnlixN1ieSagYYsYZ',
          w_public_price: 0,
          type: 'delivery_fees'
        },
        // 2 bouteilles — pt relais
        8: {
          w_id: 190,
          w_vintage: '2 bouteilles — pt relais',
          w_stripe_price_id: 'price_1OG4gYCGnlixN1ierBYaanAu',
          w_public_price: 8,
          type: 'delivery_fees'
        },
        // 2 bouteilles — colissimo
        15: {
          w_id: 191,
          w_vintage: '2 bouteilles — colissimo',
          w_stripe_price_id: 'price_1OG4gYCGnlixN1ieNsl16OjJ',
          w_public_price: 15,
          type: 'delivery_fees'
        },
        // 6 bouteilles — pt relais
        14: {
          w_id: 192,
          w_vintage: '6 bouteilles — pt relais',
          w_stripe_price_id: 'price_1OG4gYCGnlixN1ieu1iPxjpS',
          w_public_price: 14,
          type: 'delivery_fees'
        },
        // 6 bouteilles — colissimo
        21: {
          w_id: 193,
          w_vintage: '6 bouteilles — colissimo',
          w_stripe_price_id: 'price_1OG4gYCGnlixN1ieSAqMe7A3',
          w_public_price: 21,
          type: 'delivery_fees'
        },
        // 12 bouteilles — pt relais
        22: {
          w_id: 194,
          w_vintage: '12 bouteilles — pt relais',
          w_stripe_price_id: 'price_1OG4gYCGnlixN1iej47aAOIa',
          w_public_price: 22,
          type: 'delivery_fees'
        },
        // 12 bouteilles — colissimo
        33: {
          w_id: 195,
          w_vintage: '12 bouteilles — colissimo',
          w_stripe_price_id: 'price_1OG4gYCGnlixN1ieauPXaLt7',
          w_public_price: 33,
          type: 'delivery_fees'
        }
      }
    : {
        0: {
          w_id: 0,
          w_vintage: 'Livraison OFFERTE',
          w_stripe_price_id: 'price_1OHWVlCGnlixN1ie34WX9IJI',
          w_public_price: 0,
          type: 'delivery_fees'
        },
        // 2 bouteilles — pt relais
        8: {
          w_id: 131,
          w_vintage: '2 bouteilles — pt relais',
          w_stripe_price_id: 'price_1MtyfVCGnlixN1ieuwE6rL8B',
          w_public_price: 8,
          type: 'delivery_fees'
        },
        // 2 bouteilles — colissimo
        15: {
          w_id: 132,
          w_vintage: '2 bouteilles — colissimo',
          w_stripe_price_id: 'price_1MtyfVCGnlixN1ieYU7cN0Gj',
          w_public_price: 15,
          type: 'delivery_fees'
        },
        // 6 bouteilles — pt relais
        14: {
          w_id: 133,
          w_vintage: '6 bouteilles — pt relais',
          w_stripe_price_id: 'price_1MtyfVCGnlixN1ieOFBMrfHy',
          w_public_price: 14,
          type: 'delivery_fees'
        },
        // 6 bouteilles — colissimo
        21: {
          w_id: 134,
          w_vintage: '6 bouteilles — colissimo',
          w_stripe_price_id: 'price_1MtyfVCGnlixN1iee4V1mjE3',
          w_public_price: 21,
          type: 'delivery_fees'
        },
        // 12 bouteilles — pt relais
        22: {
          w_id: 135,
          w_vintage: '12 bouteilles — pt relais',
          w_stripe_price_id: 'price_1MtyfVCGnlixN1ie2uXJiiUW',
          w_public_price: 22,
          type: 'delivery_fees'
        },
        // 12 bouteilles — colissimo
        33: {
          w_id: 136,
          w_vintage: '12 bouteilles — colissimo',
          w_stripe_price_id: 'price_1MtyfVCGnlixN1iev3UP1SrK',
          w_public_price: 33,
          type: 'delivery_fees'
        }
      }

module.exports = {
  subscriptionType,
  boxType,
  prestigeShipping,
  departmentsIDF,
  allSubscriptionOptions,
  countries,
  deliveryOptions,
  stripeIds,
  deliveryStripePriceIds
}
