<template>
  <div class="flex flex-col sm:flex-row mt-4">
    <!-- Mondial Relay -->
    <div v-if="isRelayDelivery" class="flex flex-col w-full">
      <div id="Zone_Widget"></div>
    </div>

    <!-- Home Delivery -->
    <div v-else class="w-full">
      <div class="bg-white py-8 px-4 sm:rounded-sm sm:px-10">
        <form class="space-y-6">
          <div>
            <p class="text-primary text-center text-2xl font-semibold italic">Vous avez choisi la livraison :</p>
            <p class="text-primary text-center text-2xl font-semibold">À DOMICILE</p>
            <p class="text-gray-800 text-center text-xl font-light mt-8">
              Indiquez l'adresse à laquelle vous souhaitez recevoir chaque mois
            </p>
            <p class="text-gray-800 text-center text-xl font-light">
              votre coffret LA CHAUSSETTE <span class="text-sm">Ⓡ</span>
            </p>
            <div class="flex justify-center mt-10">
              <div class="flex flex-col space-y-10">
                <div class="flex flex-col sm:flex-row gap-2">
                  <TextField
                    v-model="selectedHomeDelivery.lastname"
                    id="lastname"
                    type="text"
                    label="Nom"
                    :isRequired="true"
                    :isColorLight="false"
                  ></TextField>
                  <TextField
                    v-model="selectedHomeDelivery.firstname"
                    id="firstname"
                    type="text"
                    label="Prénom"
                    :isRequired="true"
                    :isColorLight="false"
                  ></TextField>
                </div>
                <div class="flex flex-col sm:flex-row gap-2">
                  <div class="flex flex-col gap-2">
                    <TextField
                      v-model="selectedHomeDelivery.address"
                      id="address"
                      type="text"
                      label="Adresse"
                      :isRequired="true"
                      :isColorLight="false"
                    ></TextField>
                    <TextField
                      v-model="selectedHomeDelivery.city"
                      id="city"
                      type="text"
                      label="Ville"
                      :isRequired="true"
                      :isColorLight="false"
                    ></TextField>
                  </div>
                  <div class="flex flex-col gap-2">
                    <TextField
                      v-model="selectedHomeDelivery.zipcode"
                      id="zipcode"
                      type="text"
                      label="Code postal"
                      :isRequired="true"
                      :isColorLight="false"
                    ></TextField>
                    <TextField
                      v-model="selectedHomeDelivery.country"
                      id="country"
                      type="text"
                      label="Pays"
                      :isDisabled="true"
                      :isRequired="true"
                      :isColorLight="false"
                    ></TextField>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- Total Command -->
    <PaymentJourneyTotal
      @validate="validate"
      :loading="loading"
      :selectedSubscription="order.selectedSubscription"
      :selectedCountry="order.selectedCountry"
      :selectedDelivery="order.selectedDelivery"
    />
    <input hidden ref="target_widget" type="text" id="Target_Widget" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useOrderStore } from '@/stores/subscriptionOrder'
import { useAppStateStore } from '@/stores/appState'
import { useToastStore } from '@/stores/toast'
import TextField from '@/components/TextField'
import PaymentJourneyTotal from '@/components/subscriptionJourney/PaymentJourneyTotal'

import paymentsHelpers from '@/helpers/payments'
import countryHelpers from '@/helpers/country'

export default {
  name: 'DeliveryStep',
  components: {
    TextField,
    PaymentJourneyTotal
  },
  data() {
    return {
      selectedSubscription: {},
      selectedCountry: {},
      selectedDelivery: {},
      selectedRelayPoint: {},
      selectedHomeDelivery: {},
      loading: false
    }
  },
  mounted() {
    this.selectedHomeDelivery = {
      lastname: this.user.c_delivery_last_name || this.user.c_last_name,
      firstname: this.user.c_delivery_first_name || this.user.c_first_name,
      address: this.user.c_delivery_address,
      city: this.user.c_delivery_city || this.user.c_city,
      zipcode: this.user.c_delivery_zip_code || this.user.c_zip_code,
      country: this.order.selectedCountry.name
    }

    if (this.isRelayDelivery) {
      document.default_relay_point_country = this.order.selectedCountry.id
      document.default_relay_point_city = (this.order.selectedRelayPoint || {}).city || this.selectedHomeDelivery.city
      document.default_relay_point_zipcode =
        (this.order.selectedRelayPoint || {}).zipcode || this.selectedHomeDelivery.zipcode
    }
  },
  computed: {
    ...mapState(useOrderStore, {
      order: state => state
    }),
    ...mapState(useAppStateStore, {
      user: state => state.user
    }),
    isRelayDelivery() {
      return this.order.selectedDelivery.isPointRelay
    }
  },
  methods: {
    ...mapActions(useToastStore, ['showToast']),
    ...mapActions(useOrderStore, ['updateOrder']),
    getDeliveryPriceForCountry: paymentsHelpers.getDeliveryPriceForCountry,
    getFormattedDeliveryPrice: paymentsHelpers.getFormattedDeliveryPrice,
    getFormattedPrice: paymentsHelpers.getFormattedPrice,
    async validateRelayPoint() {
      if (
        document.relay_point_id === undefined ||
        document.relay_point_name === undefined ||
        document.relay_point_address === undefined ||
        document.relay_point_country === undefined
      ) {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: 'Veuillez sélectionner un point relais'
        })
        return
      }

      this.selectedRelayPoint = {
        id: document.relay_point_id,
        name: document.relay_point_name,
        address: document.relay_point_address,
        city: document.relay_point_city,
        zipcode: document.relay_point_zipcode,
        country: countryHelpers.getCountryFromId(document.relay_point_country).name
      }

      this.updateOrder({
        selectedRelayPoint: this.selectedRelayPoint
      })
      this.$emit('go-to-next-step')
    },
    validateHomeDelivery() {
      if (
        !this.selectedHomeDelivery.address ||
        !this.selectedHomeDelivery.city ||
        !this.selectedHomeDelivery.zipcode ||
        !this.selectedHomeDelivery.country ||
        !this.selectedHomeDelivery.firstname ||
        !this.selectedHomeDelivery.lastname
      ) {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: 'Veuillez renseigner toutes les informations de livraison'
        })
        return
      }

      this.updateOrder({
        selectedHomeDelivery: this.selectedHomeDelivery
      })
      this.$emit('go-to-next-step')
    },
    validate() {
      // Relay Delivery
      if (this.isRelayDelivery) {
        this.validateRelayPoint()
      }
      // Home Delivery
      else {
        this.validateHomeDelivery()
      }
    }
  }
}
</script>
