<template>
  <div
    v-if="COUNTRIES_SUPPORTED[props.country]"
    :class="'flex rounded-sm overflow-hidden w-4 h-3 items-center justify-center ' + props.class"
  >
    <flag :iso="COUNTRIES_SUPPORTED[props.country]" size="huge" />
  </div>
</template>

<script setup>
const COUNTRIES_SUPPORTED = {
  France: 'fr',
  Espagne: 'es',
  Italie: 'it',
  Autriche: 'at'
}

const props = defineProps({
  country: { type: String, required: true },
  class: { type: String, default: '' }
})
</script>

<style scoped></style>
