<template>
  <div class="flex flex-col md:flex-row mt-6">
    <!-- Left section -->
    <div class="md:w-2/3 md:mx-10 md:ml-10 md:mr-0 flex flex-col">
      <!-- Shopping Cart section -->
      <div class="flex h-full flex-col bg-white rounded-sm p-3">
        <!-- List title -->
        <div class="flex flex-col md:flex-row flex-wrap md:items-center justify-between p-3">
          <p class="text-primary text-2xl font-bold text-center w-full">Récapitulatif de commande</p>
        </div>
        <div class="w-full h-px bg-gray-100 my-1"></div>
        <!-- Articles list -->
        <div
          class="flex flex-col flex-wrap justify-around"
          :key="`wine_${article.data.w_id}`"
          v-for="(article, index) in wines"
        >
          <!-- Separator -->
          <div v-if="index > 0" class="w-full h-px bg-gray-100 my-1"></div>

          <!-- Article line -->
          <div class="flex flex-col md:flex-row flex-wrap md:items-center justify-between p-3">
            <div class="flex flex-col md:flex-row md:ml-4 md:items-center">
              <!-- Quantity selected -->
              <p class="text-gray-800 text-lg font-medium my-auto md:mr-6">
                {{ selectedArticles[article.data.w_id]?.quantity }} x
              </p>
              <!-- Wine title -->
              <p class="text-gray-800 text-base font-normal md:mr-4">
                {{ article.data.w_vintage }}
              </p>
              <div class="flex items-center">
                <Flag :country="article.data?.Domain?.Pays" class="mr-1 md:mr-4" />
                <p class="text-gray-700 text-base font-normal">
                  {{ article.data.w_grape_variety_1 }} {{ article.data.w_vintage_year }}
                </p>
              </div>
            </div>
            <!-- Wine price -->
            <div class="mt-1 md:mt-0 my-0 md:w-20 md:text-center">
              <p class="text-gray-800 text-base font-normal">{{ article.data.w_public_price }}€</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Right section -->
    <div class="mt-5 md:mt-0 md:mx-10 md:ml-5 md:mr-10 md:w-1/4 flex flex-col">
      <!-- Total section -->
      <div class="flex flex-col bg-white rounded-sm p-6 text-center">
        <p class="w-full text-primary text-2xl font-bold">Récapitulatif de livraison</p>
        <div class="flex flex-col justify-center">
          <ul role="list" class="divide-y divide-gray-200">
            <li class="px-6 text-center">
              <div class="w-full text-2xl font-bold my-5">
                <p>
                  {{ cartStore.isRelayDelivery() ? 'Livraison en point relais' : 'Livraison à domicile' }}
                </p>
                <p v-if="deliveryArticle && deliveryArticle.data">+{{ deliveryArticle.data.w_public_price }}€</p>
                <p v-else>Offerte</p>
                <!-- <p v-if="order.selectedDelivery.isPointRelay">
                  {{
                    order.selectedCountry.pointRelayPrice > 0
                      ? getFormattedPrice(order.selectedCountry.pointRelayPrice)
                      : 'Inclus'
                  }}
                </p>
                <p v-else>
                  {{
                    order.selectedCountry.homePrice > 0
                      ? getFormattedDeliveryPrice(
                          order.selectedCountry,
                          order.selectedDelivery
                        )
                      : 'Inclus'
                  }}
                </p> -->
              </div>
              <div v-if="cartStore.isRelayDelivery()" class="w-full text-gray-500 text-lg">
                {{ cartStore.selectedRelayPoint?.name }}<br />
                {{ cartStore.selectedRelayPoint?.address }}
              </div>
              <div v-else class="w-full text-gray-500 text-lg">
                {{ cartStore.selectedHomeDelivery?.firstname }} {{ cartStore.selectedHomeDelivery?.lastname }}<br />
                {{ cartStore.selectedHomeDelivery?.address }}<br />
                {{ cartStore.selectedHomeDelivery?.zipcode }} {{ cartStore.selectedHomeDelivery?.city }}<br />
                {{ cartStore.selectedHomeDelivery?.country }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col bg-white rounded-sm p-6 text-center mt-5">
        <p class="w-full text-primary text-2xl font-bold">Récapitulatif de paiement</p>
        <p class="w-full text-gray-500 text-lg">(ports inclus)</p>
        <p class="w-full text-primary text-2xl font-bold mt-5">{{ totalPrice }}</p>
      </div>
    </div>
  </div>
  <div class="flex justify-center mt-12">
    <div class="flex flex-col">
      <div v-if="user">
        <p class="text-center text-primary text-2xl">{{ user.c_first_name }} {{ user.c_last_name }}</p>
      </div>
      <p class="text-2xl text-center">Pour valider définitivement votre commande</p>
      <div class="mx-auto mt-4 w-48 h-12">
        <Button @clicked="submit" :loading="loading" class="cursor-pointer text-xl"> Payer ici </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'

import paymentHelper from '@/helpers/payments'
import paymentsApi from '../../../api/payments'
import Button from '@/components/Button'
import { useCartStore } from '@/stores/shoppingCart'
import { useAppStateStore } from '@/stores/appState'
import { useToastStore } from '@/stores/toast'
import { useRouter, useRoute } from 'vue-router'
import { subscriptionType } from '@/const/order'
import Flag from '@/components/Flag'

const loading = ref(false)
const cartStore = useCartStore()
const appStore = useAppStateStore()
const toastStore = useToastStore()
const user = computed(() => appStore.user)
const router = useRouter()
const route = useRoute()

const selectedArticles = cartStore.selectedArticles

const wines = Object.values(selectedArticles).filter(a => !a.data?.type || a.data?.type !== 'delivery_fees')
const deliveryArticle = Object.values(selectedArticles).find(a => a.data?.type === 'delivery_fees')
const emit = defineEmits(['go-to-next-step'])

const totalPrice = computed(() => {
  return paymentHelper.getFormattedPrice(
    Object.values(selectedArticles).reduce((accumulator, currentValue) => {
      return accumulator + currentValue.data.w_public_price * currentValue.quantity
    }, 0) * 100
  )
})

const submit = async () => {
  if (loading.value) return
  loading.value = true
  try {
    const lineItems = Object.entries(cartStore.selectedArticles).map(([id, article]) => ({
      id,
      stripe_price_id: article.data.w_stripe_price_id,
      quantity: article.quantity,
      price: article.data.w_public_price,
      type: article.data.type
    }))

    const response = await paymentsApi.createOrderCheckoutSession({
      lineItems,
      mode: subscriptionType.OneTime,
      successUrl: '/cart?step=completed',
      cancelUrl: '/cart?step=payment&state=cancel',
      userEmail: user.value.c_email,

      deliveryType: cartStore.selectedDeliveryType === 'home' ? 1 : 2,
      deliveryInfos:
        cartStore.selectedDeliveryType === 'home' ? cartStore.selectedHomeDelivery : cartStore.selectedRelayPoint,
      deliveryCountry: user.value.c_country // TODO : maybe add a field to select the delivery country during the payment steps of the cart
    })
    cartStore.updateCart({
      stripeCheckoutId: response.data.id
    })
    window.location = response.data.url
  } catch (err) {
    console.log(err)
    toastStore.showToast({
      error: true,
      title: 'Erreur',
      message: 'Erreur lors de la préparation du paiement\n' + err?.response?.data?.message || ''
    })
  }
  loading.value = false
}

watch(
  () => route.query,
  () => {
    if (route.query.state && route.query.state === 'cancel') {
      toastStore.showToast({
        error: true,
        title: 'Erreur',
        message: 'Votre paiement a échoué'
      })
      router.push({
        query: {
          ...route.query,
          state: undefined
        }
      })
    }
  },
  {
    deep: true,
    immediate: true
  }
)
</script>
