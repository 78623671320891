<template>
  <div class="relative flex items-start">
    <div class="flex items-center h-5">
      <input
        :id="id"
        :aria-describedby="id + '-description'"
        :name="id"
        type="checkbox"
        :checked="value"
        @input="$emit('update:modelValue', $event.target.checked)"
        class="focus:ring-0 focus:ring-offset-0 h-5 w-5 text-secondary border-gray-300 rounded-md"
      />
    </div>
    <div class="ml-3 text-sm">
      <label :for="id" class="font-normal" :class="isColorLight ? 'text-white' : 'text-gray-700'">
        {{ title }}
      </label>
      <span v-if="isRequired" class="ml-1 text-red-500 font-bold">*</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    id: {
      type: String
    },
    title: {
      type: String
    },
    value: {
      type: Boolean
    },
    isColorLight: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  }
}
</script>
