<template>
  <div class="flex flex-col sm:flex-row">
    <div>
      <div class="flex flex-col">
        <!-- Subscription Selection -->
        <div class="bg-white p-4 rounded-sm">
          <RadioGroup v-model="selectedSubscription">
            <div class="grid grid-cols-1 gap-y-6 sm:gap-x-4" :class="'sm:grid-cols-' + subscriptionOptions.length">
              <RadioGroupOption
                as="template"
                v-for="(subscriptionOption, index) in subscriptionOptions"
                :key="index"
                :value="subscriptionOption"
                v-slot="{ checked, active }"
              >
                <div
                  :class="[
                    checked ? 'border-transparent' : 'border-gray-300',
                    active ? 'ring-2 ' : '',
                    active && subscriptionOption.color === 'golden' ? 'ring-golden' : active ? 'ring-primary' : '',
                    'relative bg-gray-50 border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                  ]"
                >
                  <div class="flex-1 flex">
                    <div class="flex flex-col w-full justify-between">
                      <div
                        as="span"
                        class="block text-center text-2xl italic font-semibold"
                        :class="[subscriptionOption.color === 'golden' ? 'text-golden' : 'text-primary']"
                      >
                        {{ subscriptionOption.title }}
                      </div>
                      <div as="span" class="mt-1 text-center text-sm font-light text-gray-500">
                        {{ subscriptionOption.subtitle }}
                      </div>
                      <div class="text-sm font-light mt-1">
                        <div>• 1 chaussette</div>
                        <div>
                          • 2 bouteilles {{ subscriptionOption.boxType === boxType.prestige ? "d'exception" : '' }} /
                          {{ subscriptionOption.deliveryFrequency }}
                        </div>
                        <div class="">
                          • <strong>{{ subscriptionOption.subscription }}</strong>
                        </div>
                        <div>
                          • A partir de
                          {{ getFormattedPrice(subscriptionOption.price, subscriptionOption.paymentFrequency) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <CheckCircleIcon
                    class="h-5 w-5"
                    :class="[
                      !checked ? 'invisible' : '',
                      subscriptionOption.color === 'golden' ? 'text-golden' : 'text-primary'
                    ]"
                    aria-hidden="true"
                  />
                  <div
                    class="absolute -inset-px rounded-lg pointer-events-none"
                    :class="[
                      active ? 'border' : 'border-2',
                      !checked
                        ? 'border-transparent'
                        : subscriptionOption.color === 'golden'
                        ? 'border-golden'
                        : 'border-primary'
                    ]"
                    aria-hidden="true"
                  ></div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>

        <div class="bg-white p-4 rounded-sm mt-4">
          <div class="flex flex-col">
            <!-- Country Dropdown -->
            <Listbox as="div" v-model="selectedCountry" class="w-48">
              <ListboxLabel class="block text-sm font-medium text-gray-700"> Votre Pays </ListboxLabel>
              <div class="mt-1 relative">
                <ListboxButton
                  class="bg-gray-50 relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm"
                >
                  <span class="block truncate">{{ selectedCountry.flag }} {{ selectedCountry.name }}</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition ease-in duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    class="absolute z-10 mt-1 w-full bg-gray-50 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  >
                    <ListboxOption
                      as="template"
                      v-for="country in countries"
                      :key="country.id"
                      :value="country"
                      v-slot="{ active, selected }"
                    >
                      <li
                        :class="[
                          active ? 'text-white bg-primary' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9'
                        ]"
                      >
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                          {{ country.flag }} {{ country.name }}
                        </span>

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-primary',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>

            <!-- Delivery Selection -->
            <div class="mt-6">
              <RadioGroup v-model="selectedDelivery">
                <div class="grid grid-cols-1 gap-y-6 sm:gap-x-4" :class="'sm:grid-cols-' + deliveryOptions.length">
                  <RadioGroupOption
                    as="template"
                    v-for="deliveryOption in deliveryOptions"
                    :key="deliveryOption.id"
                    :value="deliveryOption"
                    :disabled="!selectedCountry.pointRelayAvailable && deliveryOption.isPointRelay"
                    v-slot="{ checked, active }"
                  >
                    <div>
                      <!-- Delivery Card -->
                      <Div
                        :tooltipText="deliveryOption.giftDescription(isGift)"
                        :class="[
                          !selectedCountry.pointRelayAvailable && deliveryOption.isPointRelay
                            ? 'opacity-25 cursor-not-allowed'
                            : 'cursor-pointer focus:outline-none',
                          active ? 'ring-2 ring-primary' : '',
                          checked ? 'border-transparent' : 'border-gray-300',
                          active ? 'ring-2 ring-primary' : '',
                          'flex w-full relative bg-gray-50 border rounded-lg shadow-sm cursor-pointer focus:outline-none'
                        ]"
                        isFull
                      >
                        <div class="flex w-full p-4">
                          <div class="flex-1 flex">
                            <div class="flex flex-col w-full justify-between">
                              <div
                                as="span"
                                class="block text-center mx-auto text-lg italic font-semibold text-primary"
                              >
                                {{ deliveryOption.title }}
                              </div>
                              <div as="span" class="mt-1 mx-auto text-sm font-light text-gray-500">
                                {{ deliveryOption.getDescription(selectedSubscription.deliveryFrequency) }}
                              </div>
                              <div as="span" class="block text-center mx-auto text-lg font-semibold text-gray-900">
                                {{
                                  getFormattedDeliveryPrice(
                                    selectedCountry,
                                    deliveryOption,
                                    selectedSubscription.deliveryFrequency
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                          <CheckCircleIcon
                            :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-primary']"
                            aria-hidden="true"
                          />
                          <div
                            :class="[
                              active ? 'border' : 'border-2',
                              checked ? 'border-primary' : 'border-transparent',
                              'absolute -inset-px rounded-lg pointer-events-none'
                            ]"
                            aria-hidden="true"
                          />
                        </div>
                      </Div>
                    </div>
                  </RadioGroupOption>
                </div>
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Total Command -->
    <PaymentJourneyTotal
      @validate="validateOrder"
      :loading="loading"
      :selectedSubscription="selectedSubscription"
      :selectedCountry="selectedCountry"
      :selectedDelivery="selectedDelivery"
      class="mt-4 sm:mt-0"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useOrderStore } from '@/stores/subscriptionOrder'
import { useToastStore } from '@/stores/toast'
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckCircleIcon, CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import PaymentJourneyTotal from '@/components/subscriptionJourney/PaymentJourneyTotal'
import Div from '@/components/Div'
import paymentsHelpers from '@/helpers/payments'
import classHelper from '@/helpers/classHelper'
import { boxType, allSubscriptionOptions, countries, deliveryOptions, subscriptionType, stripeIds } from '@/const/order'

const envStripeIds = stripeIds(process.env.NODE_ENV)

export default {
  components: {
    RadioGroup,
    RadioGroupOption,
    CheckCircleIcon,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
    Div,
    PaymentJourneyTotal
  },
  data() {
    return {
      loading: false,
      selectedSubscription: {},
      selectedCountry: {},
      selectedDelivery: {},
      boxType,
      allSubscriptionOptions,
      countries,
      deliveryOptions,
      subscriptionType
    }
  },
  mounted() {
    // Select first country by default
    if (this.order.selectedCountry && Object.keys(this.order.selectedCountry).length > 0) {
      this.selectedCountry = countries.find(country => country.id === this.order.selectedCountry.id)
    } else {
      this.selectedCountry = countries[0]
    }

    // Select delivery from store
    if (this.order.selectedDelivery && Object.keys(this.order.selectedDelivery).length > 0) {
      this.selectedDelivery = deliveryOptions.find(delivery => delivery.id === this.order.selectedDelivery.id)
    }

    // Select subscription from store
    if (this.order.selectedSubscription && Object.keys(this.order.selectedSubscription).length > 0) {
      this.selectedSubscription = this.subscriptionOptions.find(
        subscription => subscription.id === this.order.selectedSubscription.id
      )
    }
  },
  computed: {
    ...mapState(useOrderStore, {
      order: state => state
    }),
    subscriptionOptions() {
      return allSubscriptionOptions[this.order.paymentJourneyType]
    },
    isGift() {
      return this.order.paymentJourneyType === 'gift'
    }
  },
  methods: {
    ...mapActions(useToastStore, ['showToast']),
    ...mapActions(useOrderStore, ['updateOrder']),
    textColor: classHelper.textColor,
    ringColor: classHelper.ringColor,
    borderColor: classHelper.borderColor,
    getDeliveryPriceForCountry: paymentsHelpers.getDeliveryPriceForCountry,
    getFormattedDeliveryPrice: paymentsHelpers.getFormattedDeliveryPrice,
    getFormattedPrice: paymentsHelpers.getFormattedPrice,
    async validateOrder() {
      if (this.loading) return
      this.loading = true
      try {
        // const profile = await authenticationApi.requestPasswordReset(this.email);
        this.updateOrder({
          selectedSubscription: this.selectedSubscription,
          selectedCountry: this.selectedCountry,
          selectedDelivery: this.selectedDelivery,
          stripePriceId: this.getStripeId()
        })

        this.$emit('go-to-next-step')
      } catch (err) {
        console.error(err)
      }
      this.loading = false
    },
    getStripeId() {
      let price = this.selectedSubscription.price
      if (this.selectedDelivery.isPointRelay) {
        price += this.selectedSubscription.monthsToPay * this.selectedCountry.pointRelayPrice
      } else {
        price += this.selectedSubscription.monthsToPay * this.selectedCountry.homePrice
      }
      return envStripeIds[price]
    }
  },
  watch: {
    selectedCountry: {
      handler(newCountry) {
        if (newCountry.pointRelayAvailable === false) {
          this.selectedDelivery = deliveryOptions.find(delivery => delivery.isPointRelay === false)
        }
      },
      immediate: true
    },
    '$route.query': {
      handler() {
        if (this.$route.query.paymentJourneyType) {
          this.selectedSubscription = {}
          this.selectedDelivery = {}
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
