<template>
  <div class="lg:flex">
    <div class="w-full lg:w-3/5 mx-auto">
      <RegisterForm :fields="fields" :isUpdate="true" @success="activateAccount" title="Mes informations" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useOrderStore } from '@/stores/subscriptionOrder'
import { useAppStateStore } from '@/stores/appState'
import { useToastStore } from '@/stores/toast'
import RegisterForm from '@/components/RegisterForm'
import moment from 'moment'

export default {
  name: 'IdentificationStepGiftActivation',
  components: {
    RegisterForm
  },
  computed: {
    ...mapState(useOrderStore, {
      order: state => state
    }),
    ...mapState(useAppStateStore, {
      user: state => state.user
    }),
    isUser() {
      return !!this.user
    }
  },
  data() {
    return {
      loading: false,
      sendEmailDisabled: false,
      fields: {},
      authorization: null
    }
  },

  mounted() {
    this.fields = {
      registerEmail: { value: this.user.c_email },
      firstname: { value: this.user.c_firstName },
      lastname: { value: this.user.c_lastName },
      ...this.fields,
      birthdate: { value: moment(this.user.c_birth_date).format('YYYY-MM-DD') },
      address: { value: this.user.c_delivery_address },
      city: { value: this.user.c_delivery_city },
      zipcode: { value: this.user.c_delivery_zip_code },
      country: { value: this.user.c_delivery_country },
      //phone: { value: `+${this.user.c_phone_number}${this.user.c_phone_indicative}` },
      newslettersApproved: { value: this.user.c_newsletters_approved }
    }
  },

  methods: {
    ...mapActions(useToastStore, ['showToast']),
    ...mapActions(useOrderStore, ['updateOrder']),
    async activateAccount() {
      this.$emit('go-to-next-step')
    }
  },
  watch: {
    '$route.query': {
      handler() {
        if (this.$route.query.authorization) {
          const params = this.$route.query
          this.fields = {
            ...this.fields,
            registerEmail: { value: params.email },
            firstname: { value: params.firstName },
            lastname: { value: params.lastName }
          }
          this.authorization = this.$route.query.authorization
          localStorage.setItem('Authorization', this.authorization)
          this.updateOrder({
            selectedDelivery: {
              isPointRelay: this.$route.query.deliveryType === '2'
            }
          })

          this.$router.push({
            query: {
              ...this.$route.query,
              paymentJourneyType: undefined,
              authorization: undefined,
              email: undefined,
              firstName: undefined,
              lastName: undefined,
              deliveryType: undefined
            }
          })
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
