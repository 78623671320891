<template>
  <div class="min-h-full flex flex-col justify-center py-10 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 v-if="title" class="text-center text-xl font-extrabold text-gray-900">
        {{ title }}
      </h2>
      <div class="h-0.5 bg-primary mt-5"/>
      <p v-if="subtitle" class="text-center text-xl font-normal text-black-600 tracking-wider mt-5">
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentJourneyTitle',
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
}
</script>

<style scoped>

</style>
