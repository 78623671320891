import { countries } from '../const/order'

export default {
  getCountryFromId(countryId) {
    return countries.find((country) => country.id === countryId)
  },
  getCountryFromName(countryName) {
    return countries.find((country) => country.name === countryName)
  },
}
