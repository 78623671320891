import CartStep from '@/components/cartJourney/steps/CartStep'
import DeliveryTypeStep from '@/components/cartJourney/steps/DeliveryTypeStep'
import DeliveryStepCart from '@/components/cartJourney/steps/DeliveryStepCart'
import PaymentStepCart from '@/components/cartJourney/steps/PaymentStepCart'
import PaymentCompleteStep from '@/components/cartJourney/steps/PaymentCompleteStep'

function deliveryTypePrerequisitesValidated(cart) {
  return cart.selectedArticles && Object.keys(cart.selectedArticles).length !== 0 && cart.cartValidated
}

function deliveryPrerequisitesValidated(cart, user) {
  return deliveryTypePrerequisitesValidated(cart, user) && cart.selectedDeliveryType
}

function paymentPrerequisitesValidated(cart, user) {
  return (
    deliveryPrerequisitesValidated(cart, user) &&
    ((cart.isRelayDelivery() && cart.selectedRelayPoint) ||
      (cart.isHomeDelivery() &&
        cart.selectedHomeDelivery &&
        cart.selectedHomeDelivery.address &&
        cart.selectedHomeDelivery.city &&
        cart.selectedHomeDelivery.zipcode &&
        cart.selectedHomeDelivery.country &&
        cart.selectedHomeDelivery.firstname &&
        cart.selectedHomeDelivery.lastname))
  )
}

function completedPrerequisitesValidated(cart, user) {
  return paymentPrerequisitesValidated(cart, user) && cart.stripeCheckoutId
}

export default {
  subscriberJourney: [
    {
      id: '01',
      name: 'Panier',
      status: 'cart',
      title: 'Commandez dès maintenant !',
      subtitle: 'Validez votre panier',
      component: CartStep
    },
    {
      id: '02',
      name: 'Confirmation et Paiement',
      status: 'payment',
      title: 'Commandez dès maintenant !',
      subtitle: 'Votre commande est bientôt terminée',
      component: PaymentStepCart,
      prerequisitesValidates: paymentPrerequisitesValidated
    },
    {
      id: '03',
      name: 'Terminée',
      status: 'completed',
      title: 'Commande',
      subtitle: 'Votre commande est en préparation !',
      component: PaymentCompleteStep,
      completed: true,
      hidden: true,
      prerequisitesValidates: completedPrerequisitesValidated
    }
  ],
  notSubscribedClientJourney: [
    {
      id: '01',
      name: 'Panier',
      status: 'cart',
      title: 'Commandez dès maintenant !',
      subtitle: 'Validez votre panier',
      component: CartStep
    },
    {
      id: '02',
      name: 'Mode de livraison',
      status: 'deliveryType',
      title: 'Commandez dès maintenant !',
      subtitle: 'Sélectionnez votre mode de livraison',
      component: DeliveryTypeStep,
      prerequisitesValidates: deliveryTypePrerequisitesValidated
    },
    {
      id: '03',
      name: 'Livraison',
      status: 'delivery',
      title: 'Commandez dès maintenant !',
      subtitle: 'Choisissez votre lieu de livraison',
      component: DeliveryStepCart,
      prerequisitesValidates: deliveryPrerequisitesValidated
    },
    {
      id: '04',
      name: 'Paiement',
      status: 'payment',
      title: 'Commandez dès maintenant !',
      subtitle: 'Votre commande est bientôt terminée',
      component: PaymentStepCart,
      prerequisitesValidates: paymentPrerequisitesValidated
    },
    {
      id: '05',
      name: 'Terminée',
      status: 'completed',
      title: 'Commande',
      subtitle: 'Votre commande est en préparation !',
      component: PaymentCompleteStep,
      completed: true,
      hidden: true,
      prerequisitesValidates: completedPrerequisitesValidated
    }
  ]
}
