import { defineStore } from 'pinia'
import storesHelpers from '@/helpers/stores'

const defaultState = {
  selectedArticles: {}, // { quantity: number, data: {} }
  cartValidated: null,
  selectedCountry: { id: 0 },
  selectedDeliveryType: null,
  selectedRelayPoint: null,
  selectedHomeDelivery: null,
  stripeCheckoutId: null,
  isUserDeliveryInIDF: null,
  isUserDeliveryInFrance: null,
  isUserSubscribed: null
}

export const useCartStore = defineStore('shoppingCart', {
  state: () => defaultState,
  actions: {
    isRelayDelivery() {
      return this.selectedDeliveryType && this.selectedDeliveryType == 'relay'
    },
    isHomeDelivery() {
      return this.selectedDeliveryType && this.selectedDeliveryType == 'home'
    },
    resetShoppingCart() {
      this.selectedArticles = {}
      this.cartValidated = null
      this.selectedCountry = { id: 0 }
      this.selectedDeliveryType = null
      this.selectedRelayPoint = null
      this.selectedHomeDelivery = null
      this.stripeCheckoutId = null
      this.isUserDeliveryInIDF = null
      this.isUserDeliveryInFrance = null
      this.isUserSubscribed = null
    },
    updateCart(data) {
      for (const [key, value] of Object.entries(data)) {
        this[key] = value
      }
    },
    addArticle(article, quantity = 1) {
      const selectedArticle = this.selectedArticles[article.w_id]
      if (selectedArticle) {
        selectedArticle.quantity += quantity
      } else {
        this.selectedArticles[article.w_id] = { data: article, quantity }
      }
    },
    removeDeliveryArticle() {
      const deliveryArticle = Object.values(this.selectedArticles).find(a => a.data?.type === 'delivery_fees')
      if (deliveryArticle) {
        delete this.selectedArticles[deliveryArticle.data.w_id]
      }
    },
    addShippingFees(shippingArticle) {
      this.removeDeliveryArticle()
      this.selectedArticles[shippingArticle.w_id] = { data: shippingArticle, quantity: 1 }
    }
  },
  persist: {
    key: storesHelpers.getStorageKey('shoppingCart')
  }
})
