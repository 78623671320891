import { http } from '@/api/index'

export default {
  getPendingPaidOrdersByUser(clientId) {
    return http.get(`/pendingPaidOrderByUser?userId=${clientId}`)
  },

  getNextDelivery(clientId) {
    return http.get(`/nextDelivery?userId=${clientId}`)
  }
}
