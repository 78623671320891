<template>
  <button
    type="button"
    class="relative inline-flex items-center text-md font-medium text-center text-white rounded-lg hover:text-gray-400"
  >
    <ShoppingCartSolid v-if="articlesCount > 0" :class="iconClasses"></ShoppingCartSolid>
    <ShoppingCartOutline v-else :class="iconClasses"></ShoppingCartOutline>
    <span class="sr-only">Shopping Cart</span>
    <div
      v-if="articlesCount > 0"
      class="hidden absolute md:inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full -top-3 -right-3"
    >
      {{ displayShoppingCartCount(articlesCount) }}
    </div>
    <div class="inline-flex text-md font-medium text-center text-white ml-1 md:hidden">
      MON PANIER ({{ displayShoppingCartCount(articlesCount) }})
    </div>
  </button>
</template>

<script setup>
import { computed } from 'vue'
import { ShoppingCartIcon as ShoppingCartOutline } from '@heroicons/vue/outline'
import { ShoppingCartIcon as ShoppingCartSolid } from '@heroicons/vue/solid'

import { useCartStore } from '@/stores/shoppingCart'
import { displayShoppingCartCount } from '@/helpers/shoppingCart'

const props = defineProps({
  iconClasses: { type: String, required: false, default: 'w-10 h-10' }
})

const cartStore = useCartStore()

const articlesCount = computed(() => {
  return Object.values(cartStore.selectedArticles)
    .filter(a => !a.data?.type || a.data?.type !== 'delivery_fees')
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue.quantity
    }, 0)
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
