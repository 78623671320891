<template>
  <div class="flex flex-col sm:flex-row mt-4">
    <!-- Mondial Relay -->
    <div v-if="isRelayDelivery" class="flex flex-col w-full">
      <div id="Zone_Widget"></div>
    </div>

    <!-- Home Delivery -->
    <div v-else class="w-full">
      <div class="bg-white py-8 px-4 sm:rounded-sm sm:px-10">
        <form class="space-y-6">
          <div>
            <p class="text-primary text-center text-2xl font-semibold italic">Vous avez choisi la livraison :</p>
            <p class="text-primary text-center text-2xl font-semibold">À DOMICILE</p>
            <p class="text-gray-800 text-center text-xl font-light mt-8">
              Indiquez l'adresse à laquelle vous souhaitez recevoir vos flacons :
            </p>
            <div class="flex justify-center mt-10">
              <div class="flex flex-col space-y-10">
                <div class="flex flex-col sm:flex-row gap-2">
                  <TextField
                    v-model="selectedHomeDelivery.lastname"
                    id="lastname"
                    type="text"
                    label="Nom"
                    :isRequired="true"
                    :isColorLight="false"
                  ></TextField>
                  <TextField
                    v-model="selectedHomeDelivery.firstname"
                    id="firstname"
                    type="text"
                    label="Prénom"
                    :isRequired="true"
                    :isColorLight="false"
                  ></TextField>
                </div>
                <div class="flex flex-col sm:flex-row gap-2">
                  <div class="flex flex-col gap-2">
                    <TextField
                      v-model="selectedHomeDelivery.address"
                      id="address"
                      type="text"
                      label="Adresse"
                      :isRequired="true"
                      :isColorLight="false"
                    ></TextField>
                    <TextField
                      v-model="selectedHomeDelivery.city"
                      id="city"
                      type="text"
                      label="Ville"
                      :isRequired="true"
                      :isColorLight="false"
                    ></TextField>
                  </div>
                  <div class="flex flex-col gap-2">
                    <TextField
                      v-model="selectedHomeDelivery.zipcode"
                      id="zipcode"
                      type="text"
                      label="Code postal"
                      :isRequired="true"
                      :isColorLight="false"
                    ></TextField>
                    <TextField
                      v-model="selectedHomeDelivery.country"
                      id="country"
                      type="text"
                      label="Pays"
                      :isRequired="true"
                      :isColorLight="false"
                    ></TextField>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- Total Command -->
    <div class="mt-4 md:mt-0 md:ml-5 md:w-1/4 flex flex-col">
      <!-- Total section -->
      <div class="flex flex-col bg-white rounded-sm p-6 text-center">
        <p class="w-full text-primary text-2xl font-bold">Montant Total</p>
        <p class="w-full text-gray-500 text-lg">(ports inclus)</p>
        <p class="w-full text-primary text-2xl font-bold mt-3">{{ totalPrice }}</p>
        <button type="button" @click="validate" class="text-white bg-primary rounded-md mt-5 px-4 py-2">Valider</button>
      </div>
    </div>
    <input hidden ref="target_widget" type="text" id="Target_Widget" />
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue'

import { useCartStore } from '@/stores/shoppingCart'
import { useAppStateStore } from '@/stores/appState'
import { useToastStore } from '@/stores/toast'

import TextField from '@/components/TextField'

import paymentsHelpers from '@/helpers/payments'
import countryHelpers from '@/helpers/country'
import { validateShippingForCart } from '@/helpers/shoppingCart'
import { getShippingFeesForNumberOfBottles } from '@/helpers/shoppingCart'

const cartStore = useCartStore()
const appStore = useAppStateStore()
const toastStore = useToastStore()

const selectedHomeDelivery = ref({
  lastname: appStore.user.c_delivery_last_name || appStore.user.c_last_name,
  firstname: appStore.user.c_delivery_first_name || appStore.user.c_first_name,
  address: appStore.user.c_delivery_address || appStore.user.c_address,
  city: appStore.user.c_delivery_city || appStore.user.c_city,
  zipcode: appStore.user.c_delivery_zip_code || appStore.user.c_zip_code,
  country: appStore.user.c_delivery_country || appStore.user.c_country
})

const emit = defineEmits(['go-to-next-step'])

const isRelayDelivery = computed(() => {
  return cartStore.isRelayDelivery()
})

onMounted(() => {
  if (cartStore.isRelayDelivery()) {
    document.default_relay_point_country = cartStore.selectedCountry?.id || ''
    document.default_relay_point_city =
      (cartStore.selectedRelayPoint || {}).city || cartStore.selectedHomeDelivery?.city
    document.default_relay_point_zipcode =
      (cartStore.selectedRelayPoint || {}).zipcode || cartStore.selectedHomeDelivery?.zipcode
  }
})

async function validateRelayPoint() {
  if (
    document.relay_point_id !== undefined &&
    document.relay_point_name !== undefined &&
    document.relay_point_address !== undefined &&
    document.relay_point_country !== undefined
  ) {
    cartStore.selectedRelayPoint = {
      id: document.relay_point_id,
      name: document.relay_point_name,
      address: document.relay_point_address,
      city: document.relay_point_city,
      zipcode: document.relay_point_zipcode,
      country: countryHelpers.getCountryFromId(document.relay_point_country).name
    }

    cartStore.updateCart({
      selectedRelayPoint: cartStore.selectedRelayPoint
    })
  } else if (!cartStore.selectedRelayPoint) {
    toastStore.showToast({
      error: true,
      title: 'Erreur',
      message: 'Veuillez sélectionner un point relais'
    })
  }
}

function validateHomeDelivery() {
  if (
    !selectedHomeDelivery.value.address ||
    !selectedHomeDelivery.value.city ||
    !selectedHomeDelivery.value.zipcode ||
    !selectedHomeDelivery.value.country ||
    !selectedHomeDelivery.value.firstname ||
    !selectedHomeDelivery.value.lastname
  ) {
    toastStore.showToast({
      error: true,
      title: 'Erreur',
      message: 'Veuillez renseigner toutes les informations de livraison'
    })
    return
  }

  cartStore.updateCart({
    selectedHomeDelivery: selectedHomeDelivery.value
  })
}

async function validate() {
  cartStore.removeDeliveryArticle()
  cartStore.isRelayDelivery() ? validateRelayPoint() : validateHomeDelivery()

  const error = await validateShippingForCart(appStore.user, cartStore)
  if (error) {
    // Resetting the state
    cartStore.updateCart({
      selectedRelayPoint: null
    })
    cartStore.updateCart({
      selectedHomeDelivery: null
    })

    // Showing error
    toastStore.showToast({
      error: true,
      title: 'Erreur',
      message: error
    })
    return
  }

  emit('go-to-next-step')
}

const totalPrice = computed(() => {
  const cartPrice = Object.values(cartStore.selectedArticles)
    .filter(a => !a.data?.type || a.data?.type !== 'delivery_fees')
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue.data.w_public_price * currentValue.quantity
    }, 0)
  const shippingPrice = getShippingFeesForNumberOfBottles(cartStore)?.w_public_price || 0
  return paymentsHelpers.getFormattedPrice((cartPrice + shippingPrice) * 100)
})
</script>
