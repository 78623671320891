<template>
  <div class="flex flex-row">
    <button
      @click.stop.prevent="substract()"
      class="bg-secondary rounded-l-md disabled:opacity-50"
      :disabled="props.number <= props.minNumber"
    >
      <font-awesome-icon icon="fa-solid fa-minus" class="text-white fa-xl m-1" />
    </button>
    <div class="h-full w-16 border-t border-b border-gray-400 flex items-center justify-center p-1">
      <p>{{ props.number }}</p>
    </div>
    <button
      @click.stop.prevent="add()"
      class="bg-secondary rounded-r-md disabled:opacity-50"
      :disabled="props.number >= props.maxNumber"
    >
      <font-awesome-icon icon="fa-solid fa-plus" class="text-white fa-xl m-1" />
    </button>
  </div>
</template>

<script setup>
const props = defineProps({
  number: { type: Number, required: true },
  minNumber: { type: Number, required: false },
  maxNumber: { type: Number, required: false }
})

const emit = defineEmits(['update:number'])

function substract() {
  const newNumber = props.number - 1
  if (props.hasOwnProperty('minNumber') && newNumber < props.minNumber) return
  emit('update:number', newNumber)
}

function add() {
  const newNumber = props.number + 1
  if (props.hasOwnProperty('maxNumber') && newNumber > props.maxNumber) return
  emit('update:number', newNumber)
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
