import { defineStore } from 'pinia'
import storesHelpers from '@/helpers/stores'

const defaultState = {
  show: false,
  success: false,
  error: false,
  title: null,
  message: null
}

export const useToastStore = defineStore('toast', {
  state: () => defaultState,
  actions: {
    hideToast() {
      this.show = false
    },
    showToast(data) {
      this.success = data.success
      this.error = data.error
      this.title = data.title
      this.message = data.message
      this.show = true

      setTimeout(() => {
        this.hideToast()
      }, 4000)
    }
  }
})
