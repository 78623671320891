<template>
  <div
    class="flex flex-col md:flex-row w-full h-60 justify-between space-x-0 md:space-x-8 space-y-4 md:space-y-0 mt-10 select-none"
  >
    <div
      @click="relaySelected"
      class="flex flex-col items-center justify-center grow rounded-md cursor-pointer bg-white hover:bg-gray-200"
      v-if="relayShippingFees"
    >
      <img class="w-auto md:w-52 h-20 md:h-auto" src="@/assets/mondial_relay.png" />
      <p class="text-center mx-auto text-lg font-semibold text-gray-900">+{{ relayShippingFees?.w_public_price }}€</p>
    </div>
    <div
      @click="homeSelected"
      class="flex grow rounded-md cursor-pointer bg-white hover:bg-gray-200"
      v-if="homeShippingFees"
    >
      <div
        class="flex flex-col justify-center items-center w-auto md:w-52 h-20 md:h-auto font-semibold text-xl m-auto text-primary text-center"
      >
        <font-awesome-icon icon="fa-solid fa-boxes-packing" />
        {{ (homeShippingFees?.w_public_price ?? 0) === 0 ? 'Domicile en Ile-De-France' : 'Domicile' }}
        <p class="text-center mx-auto text-lg font-semibold text-gray-900">
          {{
            (homeShippingFees?.w_public_price ?? 0) > 0 ? `+${homeShippingFees?.w_public_price}€` : 'Livraison offerte'
          }}
        </p>
      </div>
    </div>
    <div
      @click="homeOutsideIDFSelected"
      class="flex grow rounded-md cursor-pointer bg-white hover:bg-gray-200"
      v-if="(homeShippingFees?.w_public_price ?? 0) === 0"
    >
      <div
        class="flex flex-col justify-center items-center w-auto md:w-52 h-20 md:h-auto font-semibold text-xl m-auto text-primary text-center"
      >
        <font-awesome-icon icon="fa-solid fa-boxes-packing" />
        Domicile hors Ile-De-France
        <p class="text-center mx-auto text-lg font-semibold text-gray-900">
          {{ `+${homeForcePaidShippingFees?.w_public_price}€` }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCartStore } from '@/stores/shoppingCart'
import { useToastStore } from '@/stores/toast'
import { getShippingFeesForNumberOfBottles } from '@/helpers/shoppingCart'

const emit = defineEmits(['go-to-next-step'])

const cartStore = useCartStore()
const toastStore = useToastStore()
const relayShippingFees = getShippingFeesForNumberOfBottles(cartStore, 'relay')
const homeShippingFees = getShippingFeesForNumberOfBottles(cartStore, 'home')
const homeForcePaidShippingFees = getShippingFeesForNumberOfBottles(cartStore, 'home', true)

if (!relayShippingFees && !homeShippingFees) {
  toastStore.showToast({
    error: true,
    title: 'Erreur',
    message: 'Erreur dans les informations de la commande'
  })
}

function relaySelected() {
  cartStore.selectedDeliveryType = 'relay'
  emit('go-to-next-step')
}

function homeSelected() {
  cartStore.selectedDeliveryType = 'home'
  emit('go-to-next-step')
}

function homeOutsideIDFSelected() {
  cartStore.selectedDeliveryType = 'home'
  cartStore.lineItems.push(homeForcePaidShippingFees)
  emit('go-to-next-step')
}
</script>
