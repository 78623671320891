<template>
  <div class="flex justify-center bg-yellow-300 pb-5">
    <p>Attention : Vous vous trouvez sur l'environnement de test</p>
  </div>
</template>

<script>
export default {
  name: 'PreproductionHeadband',
}
</script>

<style scoped>
</style>
