<template>
  <div class="h-max md:m-16 m-4 flex flex-col">
    <!-- Hero -->
    <div class="flex flex-col flex-1 justify-center items-center text-base font-medium text-gray-700">
      <p class="text-xl font-semibold md:mb-4">Mes Factures</p>
    </div>
    <div class="flex flex-col mt-4">
      <div class="flex h-full flex-col bg-white rounded-sm p-3">
        <!-- Loader -->
        <div v-if="loading" class="items-center justify-center flex py-4">
          <Loader />
        </div>
        <!-- Invoices list -->
        <div
          class="flex flex-col flex-wrap justify-around"
          :key="`invoice_${invoice.bi_id}`"
          v-for="(invoice, index) in invoices"
          v-else
        >
          <!-- Header Row -->
          <div
            v-if="index === 0"
            class="flex justify-center items-center flex-row p-1 md:p-3 text-gray-800 text-lg font-semibold gap-x-1"
          >
            <p class="flex flex-1">Numéro de facture</p>
            <p class="flex flex-1">Date</p>
            <p class="flex flex-1">Montant HT</p>
            <p class="flex flex-1">Montant TTC</p>
            <p class="flex flex-1">PDF</p>
          </div>
          <!-- Separator -->
          <div class="w-full h-px bg-gray-100 my-1"></div>

          <!-- Invoice line -->
          <div class="flex flex-row p-1 md:p-3 text-gray-700 text-base font-medium gap-x-1 items-center">
            <p class="flex flex-1">#{{ invoice?.bi_id }}</p>
            <p class="flex flex-1">
              {{ moment(invoice?.bi_date).format('DD MMM YYYY') }}
            </p>
            <p class="flex flex-1">{{ invoice?.bi_price_no_fee }}€</p>
            <p class="flex flex-1">{{ invoice?.bi_price_all_fee }}€</p>
            <div class="flex flex-1 items-center">
              <a class="" :href="invoice?.bi_doc_path" target="_blank" rel="noopener noreferrer">
                <div class="flex flex-row items-center justify-center rounded bg-gray-200 px-2 sm:px-4 py-2">
                  <font-awesome-icon icon="fa-solid fa-download" class="text-gray-800 mr-0 sm:mr-2" />
                  <p class="text-gray-800 text-base font-semibold hidden sm:block">Facture</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useOrderStore } from '@/stores/subscriptionOrder'
import invoicesApi from '@/api/invoices'
import { useToastStore } from '@/stores/toast'
import moment from 'moment'
import Loader from '@/components/Loader'

const orderStore = useOrderStore()
const toastStore = useToastStore()
const loading = ref(false)
const invoices = ref([])

onMounted(async () => {
  orderStore.resetOrder()
  loading.value = true
  try {
    const res = await invoicesApi.getUserInvoices()
    invoices.value = res.data
  } catch (err) {
    toastStore.showToast({
      error: true,
      title: 'Erreur',
      message: 'Erreur lors de la récupération des factures\n' + (err?.response?.data?.message || '')
    })
  }
  loading.value = false
})
</script>
