<template>
  <div class="home max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-20">
    <!-- Punchline -->
    <p class="text-center text-base font-normal text-black-600 tracking-wider mt-10">
      LA CHAUSSETTE® c’est l’association de 3 passionnés de vin :
      <br />
      Philippe, Thierry et Eric.
    </p>

    <!-- Details list of 6 -->
    <div class="flex justify-center mt-10">
      <ul role="list" class="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-3 max-w-2xl">
        <li
          v-for="member in members"
          :key="member.img"
          class="col-span-1 flex flex-col text-center rounded-lg divide-y divide-gray-200"
        >
          <div class="flex-1 flex flex-col">
            <img
              class="object-cover object-top w-44 h-44 mx-auto rounded-full aspect-auto"
              :src="member.img"
              :alt="member.img"
            />
            <h3 class="mt-6 text-gray-900 text-sm font-semibold hidden">{{ member.name }}</h3>
            <h2 class="mt-6 text-gray-900 text-sm font-normal">{{ member.description }}</h2>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { useOrderStore } from '@/stores/subscriptionOrder'

const members = [
  {
    name: 'Philippe',
    description:
      "Entrepreneur passionné et dégustateur intraitable. Même s'il a avoué un petit faible pour les vins de Bordeaux, sa région d'origine, il s’est ouvert depuis longtemps à l’ensemble des régions viticoles de France et d’ailleurs !",
    img: require('@/assets/team/profile_philippe.jpg')
  },
  {
    name: 'Thierry',
    description:
      'Entrepreneur, grand amateur de rugby et surtout de vins du Sud-Ouest sa région d’adoption. Né à Lyon, ne boude pas non plus son plaisir de faire découvrir et apprécier d’excellents Gamay et autres cépages Rhodaniens.',
    img: require('@/assets/team/profile_thierry.jpg')
  },
  {
    name: 'Eric',
    description:
      'Marketeur, toujours à la recherche de nouvelles sensations en dégustant les vins du monde entier qu’il parcourt en courant ou à skis. Totalement impartial puisque sans vignoble fixe malgré son diplôme d’œnologue de l’AGRO Montpellier.',
    img: require('@/assets/team/profile_eric.jpg')
  }
]

export default {
  setup() {
    return {
      members
    }
  },
  mounted() {
    this.resetOrder()
  },
  methods: {
    ...mapActions(useOrderStore, ['resetOrder'])
  }
}
</script>
