// /!\ This whole logic is duplicated in the backend
import moment from 'moment'
import { deliveryStripePriceIds, boxType, prestigeShipping, departmentsIDF } from '@/const/order'
import orderApi from '@/api/orders'

export const displayShoppingCartCount = count => {
  return count > 9 ? '9+' : count
}

export async function getTotalNumberOfBottlesNextDelivery(user) {
  const currentMonth = moment().month()
  const nextMonth = (currentMonth + 1) % 12 // Because moment returns a value between 0 and 11
  const currentDayOfMonth = moment().date()

  const paidSubscriptions = user.Subscriptions
  // All classic subscriptions
  // + All prestige subscriptions only if it is a month where they are shipped and if we are before the 15th (otherwise the order is going to be sent next month)
  // Or All prestige subscriptions only if they are going to be shipped next month and we are after the 15th (because the order is going to be sent next month)
  const nextMonthsSubscriptions = paidSubscriptions.filter(
    s =>
      s.s_type_box_id === boxType.classic ||
      (s.s_type_box_id === boxType.prestige && prestigeShipping.includes(currentMonth) && currentDayOfMonth <= 15) ||
      (s.s_type_box_id === boxType.prestige && prestigeShipping.includes(nextMonth) && currentDayOfMonth > 15)
  )
  // there is 2 bottles per box
  const subscriptionBottles = nextMonthsSubscriptions.length * 2

  const res = await orderApi.getNextDelivery(user.c_id)
  const ordersBottles = res.data.reduce((acc, wine) => acc + wine.quantity, 0)

  return subscriptionBottles + ordersBottles
}

export async function validateShippingForCart(user, cartStore) {
  const numberOfBottlesNextDelivery = await getTotalNumberOfBottlesNextDelivery(user)
  const articleCount = getTotalNumberOfBottlesInCart(cartStore)
  const totalBottles = numberOfBottlesNextDelivery + articleCount

  // If user is already client and will receive a box in the next shipping
  if (numberOfBottlesNextDelivery > 0) {
    // A total of 6 or 12 bottles is required if not delivered in IDF or the order is less than 12 bottles
    if (!isCartDeliveryInIDF(cartStore) || articleCount < 12) {
      if (totalBottles != 6 && totalBottles != 12) {
        return `Veuillez ne sélectionner que ${6 - numberOfBottlesNextDelivery} ou ${
          12 - numberOfBottlesNextDelivery
        } bouteilles`
      }
    }
  } else {
    if (isCartDeliveryInIDF(cartStore) && isDeliveryInFrance(cartStore)) {
      // Delivering in Ile-De-France, only apply fees when less than 12 bottles
      if (articleCount < 12) {
        const shippingArticle = getShippingFeesForNumberOfBottles(cartStore)
        cartStore.addShippingFees(shippingArticle)
      }
    } else if (isDeliveryInFrance(cartStore)) {
      // Delivering in France, apply fees for any number of bottles
      const shippingArticle = getShippingFeesForNumberOfBottles(cartStore)
      cartStore.addShippingFees(shippingArticle)
    } else {
      return "Nous ne livrons qu'en France métropolitaine pour le moment"
    }
  }
}

function getTotalNumberOfBottlesInCart(cartStore) {
  return Object.values(cartStore.selectedArticles)
    .filter(a => !a.data?.type || a.data?.type !== 'delivery_fees')
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue.quantity
    }, 0)
}

function isCartDeliveryInIDF(cartStore) {
  return departmentsIDF.includes(
    cartStore.isRelayDelivery()
      ? String(cartStore.selectedRelayPoint.zipcode)?.substring(0, 2)
      : String(cartStore.selectedHomeDelivery.zipcode)?.substring(0, 2)
  )
}

export function isUserDeliveryInIDF(user) {
  return departmentsIDF.includes(user.c_delivery_zip_code?.substring(0, 2) || user.c_zip_code?.substring(0, 2))
}

function isDeliveryInFrance(cartStore) {
  return cartStore.isRelayDelivery()
    ? cartStore.selectedRelayPoint.country.toLowerCase() === 'france'
    : cartStore.selectedHomeDelivery.country.toLowerCase() === 'france'
}

const envDeliveryStripePriceIds = deliveryStripePriceIds(process.env.NODE_ENV)

/**
 *
 * @param {*} cartStore
 * @param {*} optionnalDeliveryType
 * @param {bool} forcePaidFees Ensure no free delivery is added (so far used when having an IDF address, then choosing a paid home delivery then choosing a non-IDF delivery address)
 * @returns
 */
export function getShippingFeesForNumberOfBottles(cartStore, optionnalDeliveryType, forcePaidFees = false) {
  const articleCount = getTotalNumberOfBottlesInCart(cartStore)
  if (optionnalDeliveryType === 'relay' || (!optionnalDeliveryType && cartStore.isRelayDelivery())) {
    if (articleCount === 2) {
      return envDeliveryStripePriceIds[8]
    } else if (articleCount === 6) {
      return envDeliveryStripePriceIds[14]
    } else if (articleCount === 12) {
      return envDeliveryStripePriceIds[22]
    }
  } else {
    if (articleCount === 2) {
      return envDeliveryStripePriceIds[15]
    } else if (articleCount === 6) {
      return envDeliveryStripePriceIds[21]
    } else if (articleCount >= 12 && cartStore.isUserDeliveryInIDF && !forcePaidFees) {
      return envDeliveryStripePriceIds[0] // No shipping fees when 12 or more bottles delivered in IDF
    } else if (articleCount === 12) {
      return envDeliveryStripePriceIds[33]
    }
  }
  // throw error
  return null
}
