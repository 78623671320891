<template>
  <div class="h-max md:m-16 m-4 flex flex-col gap-8">
    <!-- Hero -->
    <div class="flex flex-col flex-1 justify-center items-center text-base font-medium text-gray-700">
      <p class="text-xl font-semibold mb-8">Nous Contacter</p>
      <div>
        <p class="mb-6">
          Vous avez une question ? N'hésitez pas à nous contacter, nous vous répondrons dès que possible.
        </p>
        <p>
          Remplissez ce formulaire ou joignez nous par email à l'adresse suivante :
          <a
            class="text-primary underline font-semibold"
            :href="`mailto:contact@fitwine.fr?subject=${formData.subject}&body=${formData.body}`"
            target="_blank"
            >contact@fitwine.fr</a
          >
        </p>
      </div>
    </div>
    <!-- Form -->
    <form
      class="flex flex-col gap-4 flex-1 justify-center bg-white py-8 px-4 shadow rounded-lg sm:px-10 w-full mx-auto xl:w-3/4"
    >
      <p class="text-center font-medium text-lg text-gray-700">Formulaire de contact</p>
      <div>
        <label for="subject" class="block text-sm font-medium text-gray-700">Objet</label>
        <div class="mt-1">
          <input
            id="subject"
            v-model="formData.subject"
            name="subject"
            type="text"
            placeholder="Entrez l'objet de votre demande ici"
            required=""
            class="appearance-none block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
          />
        </div>
      </div>
      <div>
        <label for="body" class="block text-sm font-medium text-gray-700">Message</label>
        <div class="mt-1">
          <textarea
            id="body"
            v-model="formData.body"
            name="body"
            type="text"
            placeholder="Ecrivez votre message ici"
            required=""
            rows="5"
            class="appearance-none block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
          ></textarea>
        </div>
      </div>
      <Button @clicked="onSubmit" :loading="loading">Envoyer</Button>
    </form>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useOrderStore } from '@/stores/subscriptionOrder'
import { useToastStore } from '@/stores/toast'

import Button from '@/components/Button'
import clientsApi from '@/api/clients'

const toastStore = useToastStore()
const orderStore = useOrderStore()
const loading = ref(false)
const formData = ref({
  subject: '',
  body: ''
})

onMounted(() => {
  orderStore.resetOrder()
})

const onSubmit = async () => {
  if (loading.value) return
  if (!formData.value.subject || !formData.value.body) {
    toastStore.showToast({
      error: true,
      title: 'Erreur',
      message: "L'objet et le message sont obligatoires"
    })
    return
  }
  loading.value = true
  try {
    await clientsApi.sendMessage(formData.value.subject, formData.value.body)
    formData.value.subject = ''
    formData.value.body = ''
    toastStore.showToast({
      success: true,
      title: 'Succès',
      message: 'Votre message a bien été envoyé'
    })
  } catch (err) {
    toastStore.showToast({
      error: true,
      title: 'Erreur',
      message: "Erreur lors de l'envoi de votre message\n" + (err?.response?.data?.message || '')
    })
  }
  loading.value = false
}
</script>
