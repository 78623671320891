import { http } from '@/api/index'

export default {
  getWines({ page, priceRanges, categories, search, countries, selections }) {
    return http.get('/wines', {
      params: {
        page,
        'price-ranges': priceRanges,
        categories,
        search,
        countries,
        selections,
      },
    })
  },
  getWineById(wineId) {
    return http.get(`/wines/${wineId}`)
  },
}
