const giftOptions = [
  {
    id: 'firstDeliveryToSubscriber',
    title: 'OUI !',
    description: "Je voudrais recevoir uniquement le premier coffret afin de l'offrir en main propre.",
  },
  {
    id: 'firstDeliveryToRecipient',
    title: 'NON.',
    description: 'Le bénéficiaire recevra son premier coffret après activation de son abonnement.',
  },
]

const deliveryTypes = {
  1: 'home',
  2: 'relay'
}

module.exports = {
  giftOptions,
  deliveryTypes
}
