<template>
  <div class="flex flex-col items-center mt-4">
    <div class="w-full lg:w-3/5">
      <RegisterForm @success="goToShop" title="Je n'ai pas encore de compte"/>
    </div>
    <div class="text-sm mt-2 flex flex-col sm:flex-row items-center justify-center">
      <p>Vous avez déjà un compte ?</p>
      <router-link to="/login" class="font-medium text-primary hover:text-primary ml-1">
        Connectez-vous
      </router-link>
    </div>
  </div>
</template>

<script>
import RegisterForm from '@/components/RegisterForm';

export default {
  components: {
    RegisterForm,
  },
  mounted() {
  },
  methods: {
    goToShop() {
      this.$router.push('/shop');
    }
  },
}
</script>
