import { defineStore } from 'pinia'
import storesHelpers from '@/helpers/stores'

const defaultState = {
  paymentJourneyType: '',
  selectedSubscription: null,
  selectedCountry: null,
  selectedDelivery: null,
  stripePriceId: null,
  selectedRelayPoint: null,
  stripeCheckoutId: null,
  beneficiaryInfos: null,
  selectedHomeDelivery: null
}

export const useOrderStore = defineStore('order', {
  state: () => ({ ...defaultState }), // We have to generate a new object so that state and defaultState don't point to the same object instance
  actions: {
    resetOrder() {
      for (const [key, value] of Object.entries(defaultState)) {
        this[key] = value
      }
    },
    updateOrder(data) {
      for (const [key, value] of Object.entries(data)) {
        this[key] = value
      }
    }
  },
  persist: {
    key: storesHelpers.getStorageKey('order')
  }
})
