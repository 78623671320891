<template>
  <div class="home max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-20 mb-20 flex-col justify-center" v-if="verifyingPayment">
    <p class="text-center text-2xl font-extrabold text-secondary tracking-wider">
      Merci de patienter pendant que nous validons votre commande...
    </p>
    <div class="flex justify-center">
      <svg
        class="motion-reduce:hidden animate-spin h-20 w-20 text-primary mt-20"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  </div>
  <div class="home max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10 justify-center" v-else>
    <p class="text-center text-2xl font-extrabold text-secondary tracking-wider">
      LA CHAUSSETTE® vous remercie pour votre commande !
    </p>
    <p class="text-center w-1/2 text-lg font-normal text-black-600 tracking-wider mt-10 mx-auto">
      Celle-ci sera expédiée autour du {{ expeditionDate }}.
    </p>

    <div class="flex flex-col sm:flex-row justify-center">
      <img
        class="mt-10 object-cover object-top w-44 h-44 mx-auto rounded-full aspect-auto"
        src="@/assets/orderComplete/image_vin_1.jpg"
        alt="image_vin_1"
      />
    </div>

    <p class="text-center w-1/2 text-lg font-normal text-black-600 tracking-wider mt-10 mx-auto">
      N'hésitez pas à revenir quand vous le voudrez pour trouver vos prochains flacons préférés dans la
      <router-link to="/shop" class="text-secondary font-semibold">boutique.</router-link>
    </p>

    <p class="text-center text-5xl text-primary font-semibold tracking-wider mt-10">A bientôt !</p>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import paymentsApi from '@/api/payments'
import { useCartStore } from '../../../stores/shoppingCart'
import moment from 'moment'

const verifyingPayment = ref(true)
const verifyFunction = ref(undefined)
const cartStore = useCartStore()

const currentDate = moment()
const isShippedNextMonth = currentDate.date() > 15
const expeditionDate = currentDate
  .add(isShippedNextMonth ? 1 : 0, 'months')
  .date(20)
  .format('DD MMMM')

const isPaymentValidated = async () => {
  const response = await paymentsApi.getOrderSession(cartStore.stripeCheckoutId)
  return response.data.paymentStatus === 'paid'
}

onMounted(() => {
  verifyFunction.value = setInterval(async () => {
    if (await isPaymentValidated()) {
      verifyingPayment.value = false
      clearInterval(verifyFunction.value)
      cartStore.resetShoppingCart()
    }
  }, 1000)

  return () => {
    clearInterval(this.verifyFunction)
  }
})
</script>

<style scoped></style>
