<template>
  <nav aria-label="Progress">
    <ol role="list" class="border border-gray-300 rounded-lg divide-y divide-gray-300 md:flex md:divide-y-0 cursor-default">
      <li v-for="(step, stepIdx) in stepsFiltered" :key="step.name" class="relative md:flex-1 md:flex">
        <div
          @click="changeStep(step)"
          v-if="stepIdx < currentStepIndex"
          class="group flex items-center w-full"
          :class="{'cursor-pointer': !currentStep.completed}">
          <span class="px-6 py-4 flex items-center text-sm font-medium">
            <span
              class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-secondary rounded-full"
              :class="{'group-hover:bg-primary': !currentStep.completed}">
              <CheckIcon class="w-6 h-6 text-white" aria-hidden="true" />
            </span>
            <span
              class="ml-4 text-sm font-medium text-gray-700"
              :class="{'group-hover:text-gray-900': !currentStep.completed}">
              {{ step.name }}
            </span>
          </span>
        </div>
        <div v-else-if="stepIdx === currentStepIndex" class="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
          <span class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-secondary rounded-full">
            <span class="text-secondary">{{ step.id }}</span>
          </span>
          <span class="ml-4 text-sm font-medium text-secondary border-b-2 border-secondary">{{ step.name }}</span>
        </div>
        <div v-else class="group flex items-center">
          <span class="px-6 py-4 flex items-center text-sm font-medium">
            <span class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
              <span class="text-gray-500">{{ step.id }}</span>
            </span>
            <span class="ml-4 text-sm font-medium text-gray-500">{{ step.name }}</span>
          </span>
        </div>
        <template v-if="(stepIdx !== stepsFiltered.length - 1)">
          <div class="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
            <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
              <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
            </svg>
          </div>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/solid';

export default {
  components: {
    CheckIcon,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
    stepsFiltered() {
      return this.steps.filter(step => !step.hidden);
    }
  },
  methods: {
    changeStep(step) {
      if (!this.currentStep.completed) {
        this.$router.push({query: {step: step.status}});
      }
    },
  }
}
</script>
