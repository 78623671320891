<template>
  <div class="home max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-20 mb-20 flex-col justify-center" v-if="loading">
    <p class="text-center text-2xl font-extrabold text-secondary tracking-wider">
      Merci de patienter pendant que nous validons l'envoi de votre cadeau...
    </p>
    <div class="flex justify-center">
      <svg
        class="motion-reduce:hidden animate-spin h-20 w-20 text-primary mt-20"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  </div>
  <div class="home max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10 justify-center" v-else>
    <p class="text-center text-2xl font-extrabold text-secondary tracking-wider">Votre cadeau est en chemin !</p>

    <div class="mt-10" v-if="giftActivatedInfo && subscriptionStartDate">
      <p
        class="text-center text-lg font-bold text-black-600 tracking-wider"
        v-for="sentence in sentences(subscriptionStartDate)"
        :key="sentence"
      >
        {{ sentence }}
      </p>
    </div>

    <div class="md:flex justify-center">
      <img
        class="mt-10 object-cover object-top w-44 h-44 mx-auto rounded-full aspect-auto"
        src="@/assets/orderComplete/image_vin_1.jpg"
        alt="image_vin_1"
      />
    </div>

    <p class="text-center w-1/2 text-lg font-normal text-black-600 tracking-wider mt-10 mx-auto">
      Retrouvez et commandez ensuite vos flacons préférés dans la
      <router-link to="/shop" class="text-secondary font-semibold">boutique.</router-link>
    </p>

    <p class="text-center text-5xl text-primary font-semibold tracking-wider mt-10">A bientôt !</p>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useOrderStore } from '@/stores/subscriptionOrder'
import { useAppStateStore } from '@/stores/appState'
import { useToastStore } from '@/stores/toast'
import subscriptionsApi from '@/api/subscriptions'
import dateHelper from '@/helpers/dates'

export default {
  name: 'CompleteStep',
  props: {
    sentences: {
      type: Function
    }
  },

  data() {
    return {
      loading: true,
      giftActivatedInfo: null,
      subscriptionStartDate: null
    }
  },

  computed: {
    ...mapState(useOrderStore, {
      order: state => state
    }),
    ...mapState(useAppStateStore, {
      user: state => state.user
    })
  },

  async mounted() {
    this.loading = true
    try {
      const deliveryInformation = this.order.selectedDelivery.isPointRelay
        ? {
            relayPointId: this.order.selectedRelayPoint.id,
            relayPointName: this.order.selectedRelayPoint.name,
            relayPointAddress: this.order.selectedRelayPoint.address,
            country: this.order.selectedRelayPoint.country
          }
        : {
            firstName: this.order.selectedHomeDelivery.firstname,
            lastName: this.order.selectedHomeDelivery.lastname,
            address: this.order.selectedHomeDelivery.address,
            city: this.order.selectedHomeDelivery.city,
            zipCode: this.order.selectedHomeDelivery.zipcode,
            country: this.order.selectedHomeDelivery.country
          }
      const res = await subscriptionsApi.validateGiftSubscription(this.user.c_id, deliveryInformation)
      this.giftActivatedInfo = res.data
      if (this.giftActivatedInfo) {
        this.subscriptionStartDate = dateHelper.formatDateToMonthYear(this.giftActivatedInfo.s_start_date)
      }
    } catch (err) {
      this.showToast({
        error: true,
        title: 'Erreur',
        message: err.response.data.message
      })
      console.error(err)
      this.$router.go(-1)
    }
    this.loading = false
  },

  beforeUnmount() {},

  methods: {
    ...mapActions(useToastStore, ['showToast'])
  }
}
</script>

<style scoped></style>
