<template>
  <div class="relative">
    <div @mouseover="hover = true" @mouseleave="hover = false" :class="isFull ? 'w-full' : ''">
      <slot></slot>
    </div>
    <div v-if="tooltipText && hover" class="Div-tooltip absolute z-10">
      <div class="tooltip-arrow -ml-1 left-1/2 -mt-1 bg-gray-600"></div>
      <div class="py-2 px-3 text-sm font-medium text-white bg-gray-600 rounded-lg shadow-sm min-w-max">
        <span>
          {{ tooltipText }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isFull: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String
    }
  },
  data() {
    return {
      hover: false
    }
  }
}
</script>

<style scoped>
/* Used to place the tooltip below it's sibling div */
.Div-tooltip {
  top: calc(100% + 10px);
}
</style>
