<template>
  <Disclosure as="nav" id="nav" class="bg-primary shadow-sm" v-slot="{ open }">
    <div class="flex justify-center max-w-7xl mx-auto">
      <!-- Horizontal menu only on large screens -->
      <div class="hidden md:flex justify-between h-28 mr-16">
        <div class="flex text-center">
          <div class="hidden md:-my-px md:flex md:space-x-8">
            <router-link
              :active-class="isSubscriptionActive ? 'router-link-active' : ''"
              class="text-white text-sm bg-tertiary bg-opacity-0 hover:bg-opacity-20 uppercase my-auto"
              to="/payment-journey?paymentJourneyType=subscription"
              >Je m'abonne</router-link
            >
            <router-link
              :active-class="isGiftActive ? 'router-link-active' : ''"
              class="text-white text-sm bg-tertiary bg-opacity-0 hover:bg-opacity-20 uppercase my-auto"
              to="/payment-journey?paymentJourneyType=gift"
              >J'offre un abonnement</router-link
            >
            <div class="flex-shrink-0 flex items-center z-10">
              <!-- Here exact property allows to only consider the page '/' active for this exact url, not any url starting by '/' -->
              <router-link class="bg-primary rounded-full" to="/" exact>
                <img class="hidden md:block h-36 w-auto m-2 pt-6" src="@/assets/logo.png" alt="La Chaussette" />
              </router-link>
            </div>
            <router-link
              class="text-white text-sm bg-tertiary bg-opacity-0 hover:bg-opacity-20 uppercase my-auto"
              to="/team"
              >Les compères</router-link
            >
            <router-link
              class="text-white text-sm bg-tertiary bg-opacity-0 hover:bg-opacity-20 uppercase my-auto"
              to="/login"
              >Connexion</router-link
            >
          </div>
        </div>
      </div>
      <!-- Home button still visible on mobile -->
      <div class="flex justify-between h-16 md:hidden w-full px-2">
        <div class="">
          <router-link to="/" exact class="">
            <img class="h-16 w-auto z-50" src="@/assets/logo.png" alt="La Chaussette" />
          </router-link>
        </div>
        <div class="-mr-2 flex items-center">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300"
          >
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="md:hidden">
      <div class="pt-2 pb-3 space-y-1">
        <router-link
          :active-class="isSubscriptionActive ? 'router-link-active' : ''"
          class="border-transparent text-white bg-tertiary bg-opacity-0 hover:bg-opacity-20 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          to="/payment-journey?paymentJourneyType=subscription"
          >JE M'ABONNE</router-link
        >
        <router-link
          :active-class="isGiftActive ? 'router-link-active' : ''"
          class="border-transparent text-white bg-tertiary bg-opacity-0 hover:bg-opacity-20 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          to="/payment-journey?paymentJourneyType=gift"
          >J'OFFRE UN ABONNEMENT</router-link
        >
        <router-link
          class="border-transparent text-white bg-tertiary bg-opacity-0 hover:bg-opacity-20 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          to="/team"
          >LES COMPÈRES</router-link
        >
        <router-link
          class="border-transparent text-white bg-tertiary bg-opacity-0 hover:bg-opacity-20 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          to="/login"
          >CONNEXION</router-link
        >
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'
import { mapState } from 'pinia'
import { useOrderStore } from '@/stores/subscriptionOrder'

export default {
  name: 'Header',
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    XIcon
  },
  setup() {
    return {}
  },
  props: {
    msg: String
  },
  computed: {
    ...mapState(useOrderStore, {
      order: state => state
    }),
    isGiftActive() {
      return this.order.paymentJourneyType === 'gift'
    },
    isSubscriptionActive() {
      return this.order.paymentJourneyType === 'subscription'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
