import { http } from '@/api/index'

export default {
  getAll() {
    return http.get('/clients')
  },
  getUser(clientId) {
    return http.get(`/client?clientId=${clientId}`)
  },
  getAuthenticatedUser() {
    return http.get('/authenticated-client')
  },
  updateUser(userData) {
    return http.put('/client', userData)
  },
  sendMessage(subject, body) {
    return http.post('/client/send-message', {
      subject,
      body
    })
  }
}
