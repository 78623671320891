<template>
  <div class="flex flex-col justify-center m-5 md:m-14">
    <div>
      <button
        type="button"
        @click="goBackToShop"
        class="rounded-md mb-3 flex underline items-center font-medium text-base text-gray-900 ml-3 md:ml-0"
      >
        <font-awesome-icon icon="fa-solid fa-arrow-left" class="h-5 w-5 mr-1" />Retour à la boutique
      </button>
    </div>
    <div class="flex flex-col md:w-3/4 mx-auto md:flex-row p-5 md:p-8 bg-white rounded justify-center items-start">
      <!-- Left Section -->
      <div class="md:w-1/3 mb-8 md:mr-8 md:mb-0">
        <!-- Wine Image -->
        <img
          v-show="isWineImageLoaded && !loadingWine"
          @load="wineImageFinishedLoading"
          alt="Image du vin"
          :src="wine.w_img_path"
          class="sm:rounded"
        />
        <div
          v-show="!isWineImageLoaded || loadingWine"
          class="sm:rounded bg-gray-200 flex items-center justify-center"
          :style="{ width: '420px', height: '560px' }"
        >
          <font-awesome-icon icon="fa-solid fa-wine-bottle" class="text-gray-300 fa-10x" />
        </div>
      </div>
      <!-- Right Section -->
      <div class="flex flex-col h-full flex-1 justify-between">
        <div>
          <!-- Wine Informations -->
          <div>
            <div class="mb-8 flex justify-between">
              <div>
                <div class="flex flex-row">
                  <p class="text-gray-800 text-2xl font-semibold mb-1">
                    {{ wine.w_vintage }} {{ wine.w_vintage_year }}
                  </p>
                  <div class="ml-3 mt-3 w-auto">
                    <Flag :country="wine?.Domain?.Pays" />
                  </div>
                </div>
                <div class="flex items-center">
                  <p class="text-gray-700 text-base font-normal">
                    {{
                      `${wine.w_grape_variety_1}${wine.w_grape_variety_2 ? ', ' + wine.w_grape_variety_2 : ''}${
                        wine.w_grape_variety_3 ? ', ' + wine.w_grape_variety_3 : ''
                      }${wine.w_grape_variety_4 ? ', ' + wine.w_grape_variety_4 : ''}${
                        wine.w_grape_variety_5 ? ', ' + wine.w_grape_variety_5 : ''
                      }`
                    }}
                  </p>
                </div>
              </div>
              <div class="flex flex-row">
                <Div
                  class="w-10 h-10 rounded-full flex items-center justify-center ml-3"
                  :style="[{ 'background-color': `${icon.color}54` }]"
                  :tooltipText="icon.name"
                  v-for="icon in wine.WineTags"
                  :key="icon.id"
                >
                  <font-awesome-icon :icon="icon.tagKey" class="fa-lg" :style="[{ color: icon.color }]" />
                </Div>
              </div>
            </div>
            <div v-if="wine?.w_appellation || wine?.Domain?.NomDomaine" class="mb-8 flex flex-row items-start">
              <font-awesome-icon icon="fa-regular fa-chess-rook" class="text-gray-700 fa-xl" />
              <p class="text-gray-700 text-base font-normal ml-5">
                {{
                  wine.w_appellation
                    ? wine.w_appellation + (wine?.Domain?.NomDomaine ? ' ' + wine?.Domain?.NomDomaine : '')
                    : wine?.Domain?.NomDomaine
                    ? wine?.Domain?.NomDomaine
                    : ''
                }}
              </p>
            </div>
            <div v-if="wine.w_description" class="mb-8 flex flex-row items-start">
              <font-awesome-icon icon="fa-regular fa-file-lines" class="text-gray-700 fa-xl" />
              <p class="text-gray-700 text-base font-normal ml-5">{{ wine.w_description }}</p>
            </div>
            <div v-if="wine.w_matching_food" class="mb-8 flex flex-row items-start">
              <font-awesome-icon icon="fa-solid fa-utensils" class="text-gray-700 fa-xl" />
              <p class="text-gray-700 text-base font-normal ml-5">{{ wine.w_matching_food }}</p>
            </div>
            <div v-if="wine.w_temperature_min || wine.w_temperature_max" class="mb-8 flex flex-row items-start">
              <font-awesome-icon icon="fa-solid fa-temperature-low" class="text-gray-700 fa-xl" />
              <p
                v-if="wine.w_temperature_min && wine.w_temperature_max"
                class="text-gray-700 text-base font-normal ml-5"
              >
                Température de service entre {{ wine.w_temperature_min }} et {{ wine.w_temperature_max }}°C.
              </p>
              <p v-else-if="wine.w_temperature_min" class="text-gray-700 text-base font-normal ml-5">
                Température de service à partir de {{ wine.w_temperature_min }}°C.
              </p>
              <p v-else class="text-gray-700 text-base font-normal ml-5">
                Température de service en dessous de {{ wine.w_temperature_max }}°C.
              </p>
            </div>
            <div v-if="wine.w_drinking_date_min || wine.w_drinking_date_max" class="mb-8 flex flex-row items-start">
              <font-awesome-icon icon="fa-regular fa-clock" class="text-gray-700 fa-xl" />
              <p v-if="wine.w_drinking_date_max" class="text-gray-700 text-base font-normal ml-5">
                Il est préférable de le déguster avant {{ wine.w_drinking_date_max }}.
              </p>
              <p v-else-if="wine.w_drinking_date_min" class="text-gray-700 text-base font-normal ml-5">
                Il sera meilleur en étant dégusté à partir de {{ wine.w_drinking_date_min }}.
              </p>
            </div>
            <div v-if="wine.w_alcohol_degree" class="mb-8 flex flex-row items-start">
              <font-awesome-icon icon="fa-solid fa-percent" class="text-gray-700 fa-xl" />
              <p class="text-gray-700 text-base font-normal ml-5">{{ wine.w_alcohol_degree }}%</p>
            </div>
            <div v-if="wine.w_capacity" class="mb-8 flex flex-row items-start">
              <font-awesome-icon icon="fa-solid fa-arrows-up-down" class="text-gray-700 fa-xl" />
              <font-awesome-icon icon="fa-solid fa-bottle-droplet" class="text-gray-700 fa-xl" />
              <p class="text-gray-700 text-base font-normal ml-3">{{ wine.w_capacity }} mL</p>
            </div>
          </div>
        </div>
        <!-- Footer -->
        <div>
          <!-- Wine Price -->
          <div class="mb-2 flex flex-row items-end">
            <p class="text-primary text-lg font-bold">{{ wine.w_public_price }} €</p>
            <p class="text-gray-400 text-base font-light ml-2">la bouteille</p>
          </div>
          <!-- Add to cart button -->
          <div class="flex flex-col md:flex-row items-center mb-2">
            <NumberSelector
              :number="quantity"
              :minNumber="1"
              :maxNumber="maxQuantity"
              @update:number="updateQuantity"
              class="h-8"
            ></NumberSelector>
            <div
              @click="addToShoppingCart"
              class="rounded bg-primary items-center justify-center px-4 py-2 ml-0 mt-5 md:ml-5 md:mt-0 cursor-pointer select-none"
            >
              <p class="text-white">Ajouter au panier</p>
            </div>
          </div>
          <!-- Quantity remaining -->
          <div>
            <p v-if="wine.w_available_quantity <= 12" class="text-red-300 text-base font-medium mb-5 md:mb-0">
              Plus que {{ wine.w_available_quantity }} bouteilles disponibles !
            </p>
          </div>
          <!-- Technical Sheet -->
          <a
            v-if="wine.w_taste_sheet_path && wine.w_taste_sheet_path != ''"
            class="flex flex-row-reverse"
            :href="wine.w_taste_sheet_path"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="flex flex-row items-center justify-center rounded bg-gray-200 px-4 py-2">
              <font-awesome-icon icon="fa-solid fa-download" class="text-gray-800 mr-2" />
              <p class="text-gray-800 text-base font-semibold">Fiche technique</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import winesApi from '@/api/wines'
import { useToastStore } from '@/stores/toast'
import { useCartStore } from '@/stores/shoppingCart'
import NumberSelector from '@/components/NumberSelector'
import Flag from '@/components/Flag'
import Div from '@/components/Div'

const route = useRoute()
const router = useRouter()

const toastStore = useToastStore()
const shoppingCartStore = useCartStore()

const loadingWine = ref(true)
const isWineImageLoaded = ref(false)
const wine = ref({})
const quantity = ref(1)

const availableQuantity = computed(() => {
  return wine?.value?.w_available_quantity || 0
})

const selectedQuantity = computed(() => {
  const selectedArticle = shoppingCartStore.selectedArticles[wine.value.w_id]

  return selectedArticle?.quantity || 0
})

const maxQuantity = computed(() => {
  return availableQuantity.value - selectedQuantity.value
})

onMounted(async () => {
  window.scrollTo(0, 0)
  loadingWine.value = true
  try {
    const wineId = route.params.id
    const res = await winesApi.getWineById(wineId)
    wine.value = res.data
  } catch (err) {
    console.error(err)
    toastStore.showToast({
      error: true,
      title: 'Erreur',
      message: err.response.data.message
    })
  }
  loadingWine.value = false
})

function updateQuantity(_quantity) {
  quantity.value = _quantity
}

function addToShoppingCart() {
  if (selectedQuantity.value + quantity.value > availableQuantity.value) {
    toastStore.showToast({
      error: true,
      title: 'Erreur',
      message: "Il n'y a pas assez de bouteilles de ce vin là disponibles"
    })
  } else {
    shoppingCartStore.addArticle(wine.value, quantity.value)
    quantity.value = 1
    toastStore.showToast({
      success: true,
      title: 'Félicitations',
      message: 'Votre panier a bien été mis à jour'
    })
  }
}

function wineImageFinishedLoading() {
  isWineImageLoaded.value = true
}

function goBackToShop() {
  router.push('/shop')
}
</script>
