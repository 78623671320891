<template>
  <div class="h-max md:m-16 m-4 flex flex-col">
    <!-- Hero -->
    <div class="flex flex-col flex-1 justify-center items-center text-base font-medium text-gray-700">
      <p class="text-xl font-semibold mb-8">Mes Abonnements</p>
    </div>
    <div class="flex flex-col mt-4">
      <div class="flex h-full">
        <!-- Loader -->
        <div v-if="loading" class="items-center justify-center flex py-4 flex-1">
          <Loader />
        </div>
        <!-- Subscriptions list -->
        <div v-else class="flex flex-1 flex-col gap-4 items-center justify-center">
          <div
            class="flex flex-1 flex-row bg-white rounded-md p-4 shadow text-gray-700 text-base font-medium w-full md:w-1/2"
            :key="`subscription_${subscription.s_id}`"
            v-for="subscription in subscriptions"
          >
            <div class="mr-4 w-40 h-24">
              <img class="rounded-lg" alt="coffret" :src="subscription.boxImage" />
            </div>
            <div class="flex flex-col">
              <p class="font-semibold">{{ subscription.name || `Abonnement ${subscription['box.Type']}` }}</p>
              <p>
                Début : {{ subscription.s_start_date ? moment(subscription.s_start_date).format('MMMM YYYY') : '-' }}
              </p>
              <p>Fin : {{ subscription.s_end_date ? moment(subscription.s_end_date).format('MMMM YYYY') : '-' }}</p>
              <p v-if="appStore.user.c_id === subscription['subscriber.c_id'] && subscription.price">
                Prix : {{ formatPrice(subscription) }}
              </p>
              <p>
                Livraison :
                {{ subscription.s_delivery_type === 2 ? 'Point relais' : 'Domicile' }}
              </p>
              <div
                class="flex items-center"
                v-if="
                  appStore.user.c_id !== subscription['recipient.c_id'] ||
                  appStore.user.c_id !== subscription['subscriber.c_id']
                "
              >
                <font-awesome-icon icon="fa-solid fa-gift" class="fa-lg mr-2" />
                <p v-if="appStore.user.c_id !== subscription['recipient.c_id']">
                  Offert à {{ subscription['recipient.c_first_name'] }}
                </p>
                <p v-if="appStore.user.c_id !== subscription['subscriber.c_id']">
                  Offert par {{ subscription['subscriber.c_first_name'] }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useOrderStore } from '@/stores/subscriptionOrder'
import subscriptionsApi from '@/api/subscriptions'
import { useToastStore } from '@/stores/toast'
import { useAppStateStore } from '@/stores/appState'
import moment from 'moment'
import Loader from '@/components/Loader'

const appStore = useAppStateStore()
const orderStore = useOrderStore()
const toastStore = useToastStore()
const loading = ref(false)
const subscriptions = ref([])

onMounted(async () => {
  orderStore.resetOrder()
  loading.value = true
  try {
    const res = await subscriptionsApi.getUserSubscriptions()
    subscriptions.value = res.data
  } catch (err) {
    toastStore.showToast({
      error: true,
      title: 'Erreur',
      message: 'Erreur lors de la récupération des factures\n' + (err?.response?.data?.message || '')
    })
  }
  loading.value = false
})

const formatPrice = subscription => {
  const price = subscription.price ? subscription.price / 100 : subscription['box.Price']
  let recurring = null
  if (subscription.recurringCount === 3) {
    recurring = 'trimestre'
  } else if (subscription.recurringCount === 1) {
    recurring = 'mois'
  }

  return `${price}€${recurring ? ` / ${recurring}` : ''}`
}
</script>
