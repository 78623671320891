export default {
  textColor(color) {
    return 'text-' + color
  },
  ringColor(color) {
    return 'ring-' + color
  },
  borderColor(color) {
    return 'border-' + color
  }
}
