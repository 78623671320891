export default function debounce(fn, wait) {
  let timer
  return function (...args) {
    if (timer) {
      clearTimeout(timer)
    }
    const context = this
    return new Promise((resolve) => {
      timer = setTimeout(() => {
        resolve(fn.apply(context, args))
      }, wait)
    })
  }
}
