import { defineStore } from 'pinia'
import { useOrderStore } from './subscriptionOrder'
import { useCartStore } from './shoppingCart'
import storesHelpers from '@/helpers/stores'

const defaultState = { user: null }

export const useAppStateStore = defineStore('appState', {
  state: () => defaultState,
  actions: {
    resetUser() {
      this.user = null
      localStorage.removeItem('Authorization')
    },
    resetState() {
      const orderStore = useOrderStore()
      orderStore.resetOrder()

      const shoppingCartStore = useCartStore()
      shoppingCartStore.resetShoppingCart()

      this.resetUser()
    },
    updateUser(data) {
      this.user = data
    }
  },
  persist: {
    key: storesHelpers.getStorageKey('appState')
  }
})
