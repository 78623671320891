export default {
  getTotalSubscriptionPrice(selectedSubscription, selectedCountry, selectedDelivery) {
    return (
      selectedSubscription.price +
      this.getDeliveryPriceForCountry(selectedCountry, selectedDelivery) * selectedSubscription.monthsToPay
    )
  },

  getDeliveryPriceForCountry(selectedCountry, deliveryOption) {
    if (deliveryOption.isPointRelay === true) {
      if (selectedCountry.pointRelayPrice > 0) {
        return selectedCountry.pointRelayPrice
      }
    } else {
      if (selectedCountry.homePrice > 0) {
        return selectedCountry.homePrice
      }
    }
    return 0
  },

  getFormattedDeliveryPrice(selectedCountry, deliveryOption, deliveryFrequency) {
    var deliveryOptionPrice = this.getDeliveryPriceForCountry(selectedCountry, deliveryOption)
    if (deliveryOptionPrice > 0) {
      return '+' + (deliveryOptionPrice / 100).toFixed(2) + '€' + (deliveryFrequency ? ` / ${deliveryFrequency}` : '')
    } else {
      return '+0€'
    }
  },

  getFormattedPrice(price, paymentFrequency) {
    return price / 100 + '€' + (paymentFrequency ? ` / ${paymentFrequency}` : '')
  }
}
