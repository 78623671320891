export default {
    redirectToLoginIfNotAuthenticated(router, route, resetUser, showToast) {
        const token = localStorage.getItem('Authorization');

        if (!token) {
            resetUser();
            router.push({ query: { ...route.query, step: 'identification' }});
            showToast({
                error: true,
                title: 'Vous avez été déconnecté',
                message: 'Merci de vous reconnecter pour finir votre commande',
            });
        }
    },
}