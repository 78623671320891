import axios from 'axios';

const http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});

const setupApi = () => {
    http.interceptors.request.use(config => {
        const token = localStorage.getItem('Authorization');

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    });
};

export { http, setupApi };
