import { http } from '@/api/index'

export default {
  validateGiftSubscription(clientId, deliveryInformation) {
    return http.post('/subscriptions/gift-activation', {
      clientId,
      deliveryInformation
    })
  },
  getUserSubscriptions() {
    return http.get(`/user-subscriptions`)
  }
}
