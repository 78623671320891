import OrderStep from '@/components/subscriptionJourney/steps/normal/OrderStep'
import IdentificationStep from '@/components/subscriptionJourney/steps/normal/IdentificationStep'
import IdentificationStepGiftActivation from '@/components/subscriptionJourney/steps/gift/IdentificationStepGiftActivation'
import DeliveryStepGift from '@/components/subscriptionJourney/steps/gift/DeliveryStepGift'
import DeliveryStepSubscription from '@/components/subscriptionJourney/steps/normal/DeliveryStepSubscription'
import DeliveryStepGiftActivation from '@/components/subscriptionJourney/steps/gift/DeliveryStepGiftActivation'
import PaymentStepGift from '@/components/subscriptionJourney/steps/gift/PaymentStepGift'
import PaymentStepSubscription from '@/components/subscriptionJourney/steps/normal/PaymentStepSubscription'
import CompleteStep from '@/components/subscriptionJourney/steps/normal/CompleteStep'
import CompleteStepGiftActivation from '@/components/subscriptionJourney/steps/gift/CompleteStepGiftActivation'

function identificationPrerequisitesValidated(order, user) {
  return order.selectedSubscription && order.selectedCountry && order.selectedDelivery
}

function deliveryPrerequisitesValidated(order, user) {
  return identificationPrerequisitesValidated(order, user) && user
}

function paymentPrerequisitesValidated(order, user) {
  return (
    deliveryPrerequisitesValidated(order, user) &&
    ((order.selectedDelivery.isPointRelay && order.selectedRelayPoint) ||
      (!order.selectedDelivery.isPointRelay &&
        order.selectedHomeDelivery &&
        order.selectedHomeDelivery.address &&
        order.selectedHomeDelivery.city &&
        order.selectedHomeDelivery.zipcode &&
        order.selectedHomeDelivery.country &&
        order.selectedHomeDelivery.firstname &&
        order.selectedHomeDelivery.lastname))
  )
}

function completedPrerequisitesValidated(order, user) {
  return paymentPrerequisitesValidated(order, user) && order.stripeCheckoutId
}

function paymentGiftPrerequisitesValidated(order, user) {
  return (
    deliveryPrerequisitesValidated(order, user) &&
    order.beneficiaryInfos.firstname &&
    order.beneficiaryInfos.lastname &&
    order.beneficiaryInfos.email
  )
}

function completedGiftPrerequisitesValidated(order, user) {
  return paymentGiftPrerequisitesValidated(order, user) && order.stripeCheckoutId
}

function completedGiftActivationPrerequisitesValidated(order, user) {
  return (
    user &&
    ((order.selectedDelivery.isPointRelay && order.selectedRelayPoint) ||
      (!order.selectedDelivery.isPointRelay &&
        order.selectedHomeDelivery &&
        order.selectedHomeDelivery.address &&
        order.selectedHomeDelivery.city &&
        order.selectedHomeDelivery.zipcode &&
        order.selectedHomeDelivery.country &&
        order.selectedHomeDelivery.firstname &&
        order.selectedHomeDelivery.lastname))
  )
}

export default {
  journeys: {
    gift: [
      {
        id: '01',
        name: 'Commande',
        status: 'order',
        title: 'Offrez un cadeau original',
        subtitle: 'Commandez dès maintenant !',
        component: OrderStep
      },
      {
        id: '02',
        name: 'Identification',
        status: 'identification',
        title: 'Espace abonnement',
        subtitle: 'Inscrivez-vous ou connectez-vous !',
        component: IdentificationStep,
        prerequisitesValidates: identificationPrerequisitesValidated
      },
      {
        id: '03',
        name: 'Bénéficiaire',
        status: 'beneficiary',
        title: 'Espace abonnement',
        subtitle: 'Choisissez votre lieu de livraison',
        component: DeliveryStepGift,
        prerequisitesValidates: deliveryPrerequisitesValidated
      },
      {
        id: '04',
        name: 'Paiement',
        status: 'payment',
        title: 'Espace abonnement',
        subtitle: 'Votre commande est bientôt terminée',
        component: PaymentStepGift,
        prerequisitesValidates: paymentGiftPrerequisitesValidated
      },
      {
        id: '05',
        name: 'Terminée',
        status: 'completed',
        title: 'Espace abonnement',
        subtitle: 'Votre commande est validée !',
        component: CompleteStep,
        props: {
          sentences: () => [
            "Vous recevrez un mail de confirmation d'ici peu.",
            "En pièce jointe, vous trouverez un bon cadeau à imprimer afin de l'offrir au bénéficiaire."
          ]
        },
        completed: true,
        hidden: true,
        prerequisitesValidates: completedGiftPrerequisitesValidated
      }
    ],
    giftActivation: [
      {
        id: '01',
        name: 'Identification',
        status: 'identification',
        title: 'Bienvenue sur votre espace abonnement',
        subtitle: 'Renseignez vos informations !',
        component: IdentificationStepGiftActivation
      },
      {
        id: '02',
        name: 'Livraison',
        status: 'delivery',
        title: 'Espace abonnement',
        subtitle: 'Choisissez votre lieu de livraison',
        component: DeliveryStepGiftActivation,
        prerequisitesValidates: (order, user) => user
      },
      {
        id: '03',
        name: 'Terminée',
        status: 'completed',
        title: 'Espace abonnement',
        subtitle: 'Votre cadeau est en chemin !',
        component: CompleteStepGiftActivation,
        props: {
          sentences: date => [
            `Votre premier coffret arrivera en ${date}`,
            "Vous recevrez un mail de confirmation d'ici peu."
          ]
        },
        completed: true,
        hidden: true,
        prerequisitesValidates: completedGiftActivationPrerequisitesValidated
      }
    ],
    subscription: [
      {
        id: '01',
        name: 'Commande',
        status: 'order',
        title: 'Sélectionnez votre abonnement',
        subtitle: 'Commandez dès maintenant !',
        component: OrderStep
      },
      {
        id: '02',
        name: 'Identification',
        status: 'identification',
        title: 'Espace abonnement',
        subtitle: 'Inscrivez-vous ou connectez-vous !',
        component: IdentificationStep,
        prerequisitesValidates: identificationPrerequisitesValidated
      },
      {
        id: '03',
        name: 'Livraison',
        status: 'delivery',
        title: 'Espace abonnement',
        subtitle: 'Choisissez votre lieu de livraison',
        component: DeliveryStepSubscription,
        prerequisitesValidates: deliveryPrerequisitesValidated
      },
      {
        id: '04',
        name: 'Paiement',
        status: 'payment',
        title: 'Espace abonnement',
        subtitle: 'Votre commande est bientôt terminée',
        component: PaymentStepSubscription,
        prerequisitesValidates: paymentPrerequisitesValidated
      },
      {
        id: '05',
        name: 'Terminée',
        status: 'completed',
        title: 'Espace abonnement',
        subtitle: 'Votre commande est en chemin !',
        component: CompleteStep,
        props: {
          sentences: date => [
            `Votre abonnement débutera en ${date}`,
            "Vous recevrez un mail de confirmation d'ici peu."
          ]
        },
        completed: true,
        hidden: true,
        prerequisitesValidates: completedPrerequisitesValidated
      }
    ]
  }
}
