<template>
  <div class="lg:flex justify-center mx-auto flex-col w-1/2" v-if="user && !isUserEmailValidated">
    <p class="text-center text-2xl font-extrabold text-secondary tracking-wider my-8">
      Veuillez valider votre email en cliquant sur le lien que nous venons de vous envoyer...
    </p>
    <div class="flex">
      <Button @clicked="disconnect" danger :loading="loadingDisconnect" class="cursor-pointer mr-5 sm:m-5">
        Se déconnecter
      </Button>
      <Button @clicked="sendNewEmail" :loading="loading" :disabled="sendEmailDisabled" class="cursor-pointer sm:m-5">
        Renvoyer un email
      </Button>
    </div>
  </div>
  <div class="lg:flex justify-center mx-auto flex-col md:w-2/3" v-else-if="isUserEmailValidated">
    <div class="flex flex-col justify-center bg-white p-4 rounded-sm mb-4 sm:mb-0 md:ml-4 w-full">
      <div class="text-center text-black italic text-2xl font-semibold">Votre Compte</div>
      <div class="mx-auto">
        <div class="flex flex-col sm:flex-row mt-6">
          <p class="text-black text-xl mr-2">Nom :</p>
          <p class="text-center text-primary italic text-xl">{{ user.c_first_name }} {{ user.c_last_name }}</p>
        </div>
        <div class="flex flex-col sm:flex-row mt-3">
          <p class="text-black text-xl mr-2">Email :</p>
          <p class="text-center text-primary italic text-xl">{{ user.c_email }}</p>
        </div>
      </div>
      <div class="flex flex-col sm:flex-row w-1/2 mx-auto mt-3">
        <Button @clicked="disconnect" danger :loading="loadingDisconnect" class="cursor-pointer sm:m-5">
          Se déconnecter
        </Button>
        <Button @clicked="checkEmailValidated" :loading="loading" class="cursor-pointer mt-3 sm:m-5">
          Étape suivante
        </Button>
      </div>
    </div>
  </div>
  <div class="lg:flex" v-else>
    <div class="w-full lg:w-3/5">
      <RegisterForm @success="checkEmailValidated" title="Je n'ai pas encore de compte" />
    </div>
    <div class="w-full lg:w-2/5">
      <LoginForm @success="checkEmailValidated" title="J'ai déjà un compte" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useOrderStore } from '@/stores/subscriptionOrder'
import { useAppStateStore } from '@/stores/appState'
import { useToastStore } from '@/stores/toast'
import RegisterForm from '@/components/RegisterForm'
import LoginForm from '@/components/LoginForm'
import Button from '@/components/Button'
import authenticationApi from '@/api/authentication'
import clientsApi from '@/api/clients'

export default {
  name: 'IdentificationStep',
  components: {
    RegisterForm,
    LoginForm,
    Button
  },
  data() {
    return {
      loading: false,
      loadingDisconnect: false,
      sendEmailDisabled: false,
      verifyingEmailValidation: true
    }
  },
  computed: {
    ...mapState(useOrderStore, {
      order: state => state
    }),
    ...mapState(useAppStateStore, {
      user: state => state.user
    }),
    isUser() {
      return !!this.user
    },
    isVerifyingEmailValidation() {
      return this.verifyingEmailValidation
    },
    // Check if local user has validated email
    isUserEmailValidated() {
      return this.user && this.user.c_email_validated
    }
  },
  mounted() {
    this.verifyFunction = setInterval(async () => {
      if (await this.isEmailValidated()) {
        this.verifyingEmailValidation = false
        // this.checkEmailValidated()
        clearInterval(this.verifyFunction)
      }
    }, 1000)
  },
  beforeUnmount() {
    clearInterval(this.verifyFunction)
  },
  methods: {
    ...mapActions(useToastStore, ['showToast']),
    ...mapActions(useAppStateStore, ['updateUser', 'resetUser']),
    // Check if user from database has validated email
    async isEmailValidated() {
      if (this.user) {
        const response = await clientsApi.getUser(this.user.c_id)
        this.updateUser(response.data)
        return this.user.c_email_validated
      }
    },
    checkEmailValidated() {
      if (this.isUserEmailValidated) {
        this.$emit('go-to-next-step')
        return true
      }
      return false
    },
    async emailValidated(authorization) {
      const response = await authenticationApi.validateEmail(authorization)
      this.updateUser(response.data)
      this.checkEmailValidated()
    },
    async sendNewEmail() {
      if (this.loading) return
      this.loading = true
      try {
        await authenticationApi.sendNewValidationEmail(this.user.c_email)
        this.showToast({
          success: true,
          title: 'Email envoyé',
          message: 'Nous venons de vous envoyer un email pour que vous puissiez valider votre compte'
        })
        this.sendEmailDisabled = true
        setTimeout(() => {
          this.sendEmailDisabled = false
        }, 60000)
      } catch (err) {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: err.response.data.message
        })
      }
      this.loading = false
    },
    disconnect() {
      if (this.loadingDisconnect) return
      this.loadingDisconnect = true
      setTimeout(() => {
        this.resetUser()
        this.loadingDisconnect = false
      }, 500)
    }
  },
  watch: {
    '$route.query': {
      async handler() {
        if (
          this.$route.query.step !== 'identification' ||
          !this.$route.query.authorization ||
          (await this.isEmailValidated())
        ) {
          return
        }
        if (this.$route.query.authorization) {
          this.emailValidated(this.$route.query.authorization)
          this.$router.push({
            query: {
              ...this.$route.query,
              authorization: undefined
            }
          })
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
