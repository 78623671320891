<template>
  <PreproductionHeadband v-if="isPreproduction" />
  <div class="bg-gray-100" v-if="isUserConnected">
    <ConnectedHeader />
    <router-view />
    <ConnectedFooter />
    <Toast></Toast>
  </div>
  <div class="bg-gray-100" v-else>
    <Header />
    <router-view />
    <Footer />
    <Toast></Toast>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAppStateStore } from '@/stores/appState'
import PreproductionHeadband from '@/components/PreproductionHeadband.vue'
import Header from '@/components/header/Header.vue'
import Footer from '@/components/footer/Footer.vue'
import ConnectedHeader from '@/components/header/ConnectedHeader.vue'
import ConnectedFooter from '@/components/footer/ConnectedFooter.vue'
import Toast from '@/components/Toast'

export default {
  name: 'App',
  components: {
    PreproductionHeadband,
    Header,
    Footer,
    ConnectedHeader,
    ConnectedFooter,
    Toast
  },
  computed: {
    ...mapState(useAppStateStore, {
      user: state => state && state.user
    }),
    isPreproduction() {
      return process.env.NODE_ENV === 'preproduction'
    },
    isUserConnected() {
      return !!this.user
    }
  }
}
</script>

<style></style>
