<template>
  <div class="flex flex-col sm:flex-row mt-4">
    <!-- Beneficiary informations -->
    <div class="w-full">
      <div class="bg-white py-8 px-4 sm:rounded-sm sm:px-10">
        <form class="space-y-6">
          <div>
            <p class="text-primary text-center text-2xl font-semibold italic">
              Coordonnées de l'heureux bénéficiaire :
            </p>
            <p class="text-primary text-center text-1xl font-semibold italic">
              Aucune crainte, votre bénéficiaire ne saura pas pour son cadeau !
            </p>

            <div class="flex justify-center mt-10">
              <div class="flex flex-col space-y-10">
                <div class="flex flex-col gap-2">
                  <div class="flex flex-col gap-2">
                    <TextField
                      v-model="beneficiaryInfos.lastname"
                      id="lastnameBeneficiary"
                      type="text"
                      label="Nom du bénéficiaire"
                      :isRequired="true"
                      :isColorLight="false"
                    ></TextField>
                    <TextField
                      v-model="beneficiaryInfos.firstname"
                      id="firstnameBeneficiary"
                      type="text"
                      label="Prénom du bénéficiaire"
                      :isRequired="true"
                      :isColorLight="false"
                    ></TextField>
                    <TextField
                      v-model="beneficiaryInfos.email"
                      id="emailBeneficiary"
                      type="email"
                      label="Adresse E-mail du bénéficiaire"
                      helpText="Promis pas de spam !"
                      :isRequired="true"
                      :isColorLight="false"
                    ></TextField>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- Total Command -->
    <PaymentJourneyTotal
      @validate="validate"
      :selectedSubscription="order.selectedSubscription"
      :selectedCountry="order.selectedCountry"
      :selectedDelivery="order.selectedDelivery"
    />
    <input hidden ref="target_widget" type="text" id="Target_Widget" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useOrderStore } from '@/stores/subscriptionOrder'
import { useAppStateStore } from '@/stores/appState'
import { useToastStore } from '@/stores/toast'
import TextField from '@/components/TextField'
import PaymentJourneyTotal from '@/components/subscriptionJourney/PaymentJourneyTotal'

export default {
  name: 'DeliveryStep',
  components: {
    TextField,
    PaymentJourneyTotal
  },
  data() {
    return {
      beneficiaryInfos: {}
    }
  },
  mounted() {
    if (this.order.beneficiaryInfos) {
      this.beneficiaryInfos = {
        lastname: this.order.beneficiaryInfos.lastname || '',
        firstname: this.order.beneficiaryInfos.firstname || '',
        email: this.order.beneficiaryInfos.email || ''
      }
    }
  },
  computed: {
    ...mapState(useOrderStore, {
      order: state => state
    }),
    ...mapState(useAppStateStore, {
      user: state => state.user
    })
  },
  methods: {
    ...mapActions(useToastStore, ['showToast']),
    ...mapActions(useOrderStore, ['updateOrder']),
    validate() {
      if (!this.beneficiaryInfos.firstname || !this.beneficiaryInfos.lastname || !this.beneficiaryInfos.email) {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: 'Veuillez renseigner toutes les informations du bénéficiaire'
        })
        return
      }

      // TODO : A modifier
      if (this.beneficiaryInfos.email === this.user.c_email) {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: 'Veuillez ne pas mettre votre propre adresse email'
        })
        return
      }

      this.updateOrder({
        beneficiaryInfos: this.beneficiaryInfos
      })
      this.$emit('go-to-next-step')
    }
  }
}
</script>
