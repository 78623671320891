<template>
  <div>
    <div class="flex flex-col md:flex-row md:space-x-6">
      <div class="flex flex-col bg-white p-4 rounded-sm mb-4 md:mb-0 md:ml-4 w-full md:w-1/4">
        <div class="text-center text-primary italic text-2xl font-semibold">Récapitulatif de<br />commande</div>
        <div class="flex flex-col justify-center">
          <ul role="list" class="divide-y divide-gray-200">
            <li class="px-6 py-4 text-center">
              <div class="text-xl font-extrabold">{{ order.selectedSubscription.title }}</div>
              <div class="text-xl font-extrabold">
                {{ getFormattedPrice(order.selectedSubscription.price, order.selectedSubscription.paymentFrequency) }}
              </div>
              <div class="text-lg font-light">{{ order.selectedSubscription.description }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col bg-white p-4 rounded-sm mb-4 md:mb-0 md:ml-4 w-full md:w-1/4">
        <div class="text-center text-primary italic text-2xl font-semibold">Récapitulatif de<br />livraison</div>
        <div class="flex flex-col justify-center">
          <ul role="list" class="divide-y divide-gray-200">
            <li class="px-6 py-4 text-center">
              <div class="text-xl font-extrabold">
                {{ order.selectedDelivery.title }}<br />
                <p v-if="order.selectedDelivery.isPointRelay">
                  {{
                    order.selectedCountry.pointRelayPrice > 0
                      ? getFormattedPrice(
                          order.selectedCountry.pointRelayPrice,
                          order.selectedSubscription.deliveryFrequency
                        )
                      : 'Offerte'
                  }}
                </p>
                <p v-else>
                  {{
                    order.selectedCountry.homePrice > 0
                      ? getFormattedDeliveryPrice(
                          order.selectedCountry,
                          order.selectedDelivery,
                          order.selectedSubscription.deliveryFrequency
                        )
                      : 'Offerte'
                  }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col bg-white p-4 rounded-sm mb-4 md:mb-0 md:ml-4 w-full md:w-1/4">
        <div class="text-center text-primary italic text-2xl font-semibold">Informations du<br />bénéficiaire</div>
        <div class="flex flex-col justify-center">
          <ul role="list" class="divide-y divide-gray-200">
            <li class="px-6 py-4 text-center">
              <div class="text-xl font-extrabold">
                {{ order.beneficiaryInfos.firstname }} {{ order.beneficiaryInfos.lastname }}
              </div>
              <div class="py-4 text-lg font-light break-words">
                {{ order.beneficiaryInfos.email }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col bg-white p-4 rounded-sm mb-4 md:mb-0 md:ml-4 w-full md:w-1/4">
        <div class="text-center text-primary italic text-2xl font-semibold">Récapitulatif de<br />paiement</div>
        <div class="flex flex-col justify-center">
          <ul role="list" class="divide-y divide-gray-200">
            <li class="px-6 py-4 text-center text-xl font-extrabold">Total à régler</li>
            <li class="px-6 py-4 text-center text-xl font-semibold">
              {{
                getFormattedPrice(
                  getTotalSubscriptionPrice(order.selectedSubscription, order.selectedCountry, order.selectedDelivery),
                  order.selectedSubscription.paymentFrequency
                )
              }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-12">
      <div class="flex flex-col">
        <div v-if="user">
          <p class="text-center text-primary text-2xl">{{ user.c_first_name }} {{ user.c_last_name }}</p>
        </div>
        <p class="text-2xl text-center">Pour valider définitivement votre commande</p>
        <div class="mx-auto mt-4 w-48 h-12">
          <Button @clicked="submit" :loading="loading" class="cursor-pointer text-xl"> Payer ici </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useOrderStore } from '@/stores/subscriptionOrder'
import { useAppStateStore } from '@/stores/appState'
import { useToastStore } from '@/stores/toast'
import Button from '@/components/Button'
import paymentsApi from '@/api/payments'
import paymentsHelpers from '@/helpers/payments'
import authenticationHelpers from '@/helpers/authentication'

import { subscriptionType } from '@/const/order'

export default {
  components: {
    Button
  },
  data() {
    return {
      loading: false,
      state: undefined,
      lineItems: [],
      paymentType: undefined,
      successUrl: '/payment-journey?step=completed',
      cancelUrl: '/payment-journey?step=payment&state=cancel',
      subscriptionType
    }
  },
  computed: {
    ...mapState(useOrderStore, {
      order: state => state
    }),
    ...mapState(useAppStateStore, {
      user: state => state.user
    })
  },
  methods: {
    ...mapActions(useToastStore, ['showToast']),
    ...mapActions(useOrderStore, ['updateOrder']),
    ...mapActions(useAppStateStore, ['resetUser']),
    async submit() {
      authenticationHelpers.redirectToLoginIfNotAuthenticated(this.$router, this.$route, this.resetUser, this.showToast)

      this.loading = true
      try {
        const response = await paymentsApi.createSubscriptionCheckoutSession({
          lineItems: this.lineItems,
          mode: this.paymentType,
          successUrl: this.successUrl,
          cancelUrl: this.cancelUrl,
          userEmail: this.user.c_email,

          subscriberId: this.user.c_id,
          isGift: true,
          beneficiaryInfos: this.order.beneficiaryInfos,
          deliveryType: this.order.selectedDelivery.isPointRelay ? 2 : 1,
          deliveryCountry: this.order.selectedCountry.name,
          boxType: this.order.selectedSubscription.boxType,
          ...(this.order.selectedSubscription.subscriptionType === subscriptionType.OneTime && {
            nbMonths: this.order.selectedSubscription.nbMonthsDuration
          })
        })
        this.updateOrder({
          stripeCheckoutId: response.data.id
        })
        window.location = response.data.url
      } catch (err) {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: 'Erreur lors de la préparation du paiement\n' + err.response.data.message
        })
      }
      this.loading = false
    },
    getTotalSubscriptionPrice: paymentsHelpers.getTotalSubscriptionPrice,
    getDeliveryPriceForCountry: paymentsHelpers.getDeliveryPriceForCountry,
    getFormattedDeliveryPrice: paymentsHelpers.getFormattedDeliveryPrice,
    getFormattedPrice: paymentsHelpers.getFormattedPrice
  },
  watch: {
    '$route.query': {
      handler() {
        // Payment State
        if (this.$route.query.state) {
          this.state = this.$route.query.state

          if (this.state === 'cancel') {
            this.showToast({
              error: true,
              title: 'Erreur',
              message: 'Votre paiement a échoué'
            })
          }

          this.$router.push({
            query: {
              ...this.$route.query,
              state: undefined
            }
          })
        } else {
          this.lineItems = [
            {
              price: this.order.stripePriceId,
              quantity: 1
            }
          ]
          if (this.order?.selectedSubscription) {
            this.paymentType = this.order.selectedSubscription.subscriptionType
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
