<template>
  <div class="mx-auto max-w-7xl px-4 py-4 sm:px-6 md:px-8" v-if="currentStep">
    <PaymentJourneyTitle :title="currentStep.title" :subtitle="currentStep.subtitle" />
    <PaymentJourneySteps :steps="steps" :current-step-index="currentStepIndex" />
    <component
      @goToNextStep="goToNextStep"
      v-if="currentStep.component"
      :is="currentStep.component"
      v-bind="{ ...currentStep.props }"
      class="mt-4"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useCartStore } from '@/stores/shoppingCart'
import { useAppStateStore } from '@/stores/appState'
import PaymentJourneyTitle from '@/components/subscriptionJourney/PaymentJourneyTitle'
import PaymentJourneySteps from '@/components/subscriptionJourney/PaymentJourneySteps'
import paymentJourneys from '@/const/cartPaymentJourney'

const router = useRouter()
const route = useRoute()
const cartStore = useCartStore()
const appStore = useAppStateStore()

const isUserSubscribed = appStore?.user?.Subscriptions?.length ?? 0 > 0

const steps = ref(isUserSubscribed ? paymentJourneys.subscriberJourney : paymentJourneys.notSubscribedClientJourney)
const currentStepIndex = ref(0)
const currentStep = computed(() => {
  return steps.value[currentStepIndex.value]
})

function goToNextStep() {
  const nextStep = steps.value[currentStepIndex.value + 1]
  window.scrollTo(0, 0)
  router.push({ query: { ...route.query, step: nextStep.status } })
}

watch(
  () => route.query,
  () => {
    let urlStepIndex = steps.value.findIndex(s => s.status === route.query.step)

    while (urlStepIndex > 0) {
      if (
        !steps.value[urlStepIndex].prerequisitesValidates ||
        steps.value[urlStepIndex].prerequisitesValidates(cartStore, appStore.user)
      ) {
        break
      }
      --urlStepIndex
    }
    currentStepIndex.value = urlStepIndex >= 0 ? urlStepIndex : 0

    router.push({
      query: {
        ...route.query,
        step: currentStep.value.status
      }
    })
  },
  {
    deep: true,
    immediate: true
  }
)
</script>
