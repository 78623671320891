<template>
  <!-- Info message -->
  <div class="flex flex-col mt-4 text-gray-400 text-center md:w-3/4">
    <!--Delivery is in IDF -->
    <p v-if="cartStore?.isUserDeliveryInIDF" class="md:mr-10">
      La livraison à domicile est offerte en Ile de France à partir de 12 bouteilles
    </p>
    <p v-if="cartStore?.isUserSubscribed && cartStore?.isUserDeliveryInIDF" class="md:mr-10">
      Sinon vous pouvez commander {{ targetValuesFormatted }} bouteilles
    </p>
    <!-- User is subscribed and delivery is not in IDF -->
    <p v-if="cartStore?.isUserSubscribed && !cartStore?.isUserDeliveryInIDF" class="md:mr-10">
      Vous pouvez commander {{ targetValuesFormatted }} bouteilles
    </p>
    <!-- User is subscribed -->
    <p v-if="cartStore?.isUserSubscribed" class="md:mr-10">
      Votre commande sera expédiée sans frais avec votre prochain coffret qui contient déjà
      {{ totalNumberOfBottlesNextDelivery }} bouteilles
    </p>
    <!-- User is not subscribed and delivery is in IDF -->
    <p v-if="!cartStore?.isUserSubscribed && cartStore?.isUserDeliveryInIDF" class="md:mr-10">
      Sinon vous pouvez commander {{ targetValuesFormatted }} bouteilles
    </p>
    <!-- User is not subscribed and delivery is not in IDF -->
    <p v-if="!cartStore?.isUserSubscribed && !cartStore?.isUserDeliveryInIDF" class="md:mr-10">
      Vous pouvez commander {{ targetValuesFormatted }} bouteilles
    </p>
    <!-- User is not subscribed and delivery is not in France -->
    <p
      v-if="!cartStore?.isUserSubscribed && !cartStore?.isUserDeliveryInIDF && !cartStore?.isUserDeliveryInFrance"
      class="md:mr-10"
    >
      Attention : nous ne livrons qu'en France métropolitaine pour le moment
    </p>
  </div>
  <div class="flex flex-col md:flex-row mt-4">
    <!-- Left section -->
    <div class="md:w-2/3 md:mx-10 md:ml-10 md:mr-5 flex flex-col h-auto">
      <!-- Shopping Cart section -->
      <div class="flex flex-col bg-white h-full rounded-sm p-3 w-auto">
        <!-- Articles list -->
        <div v-if="wines.length > 0">
          <div
            class="flex flex-col flex-wrap justify-around"
            :key="`wine_${article.data.w_id}`"
            v-for="(article, index) in wines"
          >
            <!-- Separator -->
            <div v-if="index > 0" class="w-full h-px bg-gray-100 my-1"></div>

            <!-- Article line -->
            <router-link
              class="flex flex-col md:flex-row flex-wrap md:items-center justify-between hover:bg-gray-100 rounded-sm p-3"
              :to="`/wines/${article.data.w_id}`"
            >
              <div class="flex flex-row items-center md:w-1/2">
                <!-- Wine image -->
                <div class="rounded-sm overflow-hidden mr-4 h-28 w-20">
                  <img
                    v-show="winesImagesLoaded[article.data.id]"
                    @load="() => setWinesImagesLoaded(article.data.id)"
                    alt="Image du vin"
                    :src="article.data.w_img_path"
                    class="object-cover object-bottom h-28 w-20"
                  />
                  <div
                    v-show="!winesImagesLoaded[article.data.id]"
                    class="bg-gray-200 flex items-center justify-center"
                    :style="{ width: '380px', height: '240px' }"
                  >
                    <font-awesome-icon icon="fa-solid fa-wine-bottle" class="text-gray-300 fa-10x" />
                  </div>
                </div>
                <!-- Wine title -->
                <div class="text-gray-800 text-base font-normal">
                  <p class="">
                    {{ article.data.w_vintage }}
                  </p>
                  <div class="flex items-center">
                    <Flag :country="article.data?.Domain?.Pays" class="mr-1" />
                    <p class="text-gray-700 text-base font-normal">
                      {{ article.data.w_grape_variety_1 }} {{ article.data.w_vintage_year }}
                    </p>
                  </div>
                  <!-- Quantity remaining -->
                  <div>
                    <p
                      v-if="article.data.w_available_quantity <= 12"
                      class="text-red-300 text-base font-medium mb-5 md:mb-0"
                    >
                      Plus que {{ article.data.w_available_quantity }} bouteilles disponibles !
                    </p>
                  </div>
                </div>
              </div>
              <!-- Wine price -->
              <div class="my-3 md:my-0 w-20 text-center md:w-auto">
                <p class="text-gray-600 text-sm font-normal">{{ article.data.w_public_price }}€</p>
              </div>
              <div class="flex flex-row items-center mx-auto md:mx-0">
                <!-- Add/Remove component -->
                <NumberSelector
                  :number="selectedArticles[article.data.w_id]?.quantity"
                  :minNumber="1"
                  :maxNumber="article.data.w_available_quantity"
                  @update:number="
                    quantity => {
                      updateQuantity(article.data.w_id, quantity)
                    }
                  "
                  class="h-8 my-auto mr-4"
                ></NumberSelector>
                <!-- Trash component -->
                <button
                  type="button"
                  @click.stop.prevent="removesFromShoppingCart(article)"
                  class="text-gray-700 h-6 w-6"
                >
                  <TrashIcon></TrashIcon>
                  <span class="sr-only">Remove</span>
                </button>
              </div>
            </router-link>
          </div>
        </div>
        <div v-else class="flex justify-center items-center w-full h-full">
          <p class="text-gray-500 text-lg font-medium text-center w-3/4 md:w-1/2">
            Votre panier est vide, dirigez-vous vers la boutique pour le remplir
          </p>
        </div>
      </div>
    </div>
    <!-- Right section -->
    <div class="mt-5 md:mt-0 md:mx-10 md:ml-5 md:mr-10 md:w-1/4 flex flex-col">
      <!-- Total section -->
      <div class="flex flex-col bg-white rounded-sm p-6 text-center">
        <p class="w-full text-primary text-2xl font-bold">Montant Total</p>
        <p class="w-full text-black font-semibold">({{ numberOfBottlesInCart }} articles)</p>
        <p class="w-full text-primary text-2xl font-bold mt-3">{{ totalPrice }}</p>
        <p :class="[isCartValid ? 'text-primary' : 'text-quaternary', 'w-full text-md font-medium mt-5']">
          {{ bottleLeftMessage }}
        </p>
        <WineProgress
          :currentNumber="numberOfBottlesInCart"
          :targetNumber="nextBottleTarget === Infinity ? 12 : nextBottleTarget"
          :isCartValid="isCartValid"
        />
        <button
          type="button"
          @click="validateShoppingCart"
          :disabled="!isCartValid"
          class="text-white bg-primary rounded-md mt-5 px-4 py-2 disabled:opacity-50"
        >
          Valider ma commande
        </button>
        <button type="button" @click="goBackToShop" class="text-white bg-gray-500 rounded-md mt-5 px-4 py-2">
          Retour à la boutique
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { TrashIcon } from '@heroicons/vue/outline'
import { onMounted, ref, computed } from 'vue'

import NumberSelector from '@/components/NumberSelector'
import { useCartStore } from '@/stores/shoppingCart'
import { useAppStateStore } from '@/stores/appState'
import paymentHelper from '@/helpers/payments'
import { getTotalNumberOfBottlesNextDelivery, isUserDeliveryInIDF } from '@/helpers/shoppingCart'
import { useRouter } from 'vue-router'
import Flag from '@/components/Flag'
import WineProgress from '../WineProgress.vue'
import { deliveryTypes } from '@/const/delivery'
import clientsApi from '@/api/clients'

const router = useRouter()
const cartStore = useCartStore()
const appStore = useAppStateStore()

const selectedArticles = cartStore.selectedArticles
const winesImagesLoaded = ref({})
const currentBottleNumber = ref(0)
const nextBottleTarget = ref(0)
const totalNumberOfBottlesNextDelivery = ref(0)
const numberOfBottlesInCart = ref(0)
const targetValues = ref([])
const targetValuesFormatted = ref('')

const wines = computed(() => {
  return Object.values(selectedArticles).filter(a => !a.data?.type || a.data?.type !== 'delivery_fees')
})

const emit = defineEmits(['go-to-next-step'])

const totalPrice = computed(() => {
  return paymentHelper.getFormattedPrice(
    wines.value.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.data.w_public_price * currentValue.quantity
    }, 0) * 100
  )
})

const bottleLeftMessage = computed(() => {
  // If nextBottleTarget is Infinity then there is no requirement and it's all good
  if (nextBottleTarget.value !== Infinity) {
    const neededBottles = nextBottleTarget.value - numberOfBottlesInCart.value
    const maxValue = targetValues.value[targetValues.value.length - 1]
    if (neededBottles > 0) {
      return `Encore ${neededBottles} bouteille${neededBottles === 1 ? '' : 's'} pour passer commande`
    } else if (numberOfBottlesInCart.value > maxValue) {
      return `Les commandes sont limitées à ${maxValue} bouteilles`
    }
  }
  return "C'est tout bon !"
})

const isCartValid = computed(() => {
  if (nextBottleTarget.value === Infinity && currentBottleNumber.value > 0) return true
  const neededBottles = nextBottleTarget.value - numberOfBottlesInCart.value
  if (neededBottles !== 0 || numberOfBottlesInCart.value > nextBottleTarget.value) {
    return false
  }
  return true
})

onMounted(async () => {
  const res = await clientsApi.getAuthenticatedUser()
  const user = res?.data
  if (user) {
    appStore.updateUser(user)
  }
  await updateBottleNumbers()
})

async function updateQuantity(id, _quantity) {
  selectedArticles[id].quantity = _quantity
  updateBottleNumbers()
}

function removesFromShoppingCart(article) {
  delete selectedArticles[article.data.w_id]
  updateBottleNumbers()
}

function validateShoppingCart() {
  cartStore.cartValidated = true
  if (cartStore.isUserSubscribed) {
    // Assign same delivery type as subscription or home if delivery in IDF and ordering more than 12 bottles
    if (cartStore.isUserDeliveryInIDF && numberOfBottlesInCart.value >= 12) {
      cartStore.selectedDeliveryType = 'home'
    } else {
      const lastUserSubscription = appStore.user.Subscriptions[0]
      cartStore.selectedDeliveryType = deliveryTypes[lastUserSubscription.s_delivery_type]
    }
    cartStore.selectedRelayPoint = {
      id: appStore.user.c_relay_point_id,
      name: appStore.user.c_relay_point_name,
      address: appStore.user.c_relay_point_address,
      country: appStore.user.c_relay_point_country
    }
    cartStore.selectedHomeDelivery = {
      lastname: appStore.user.c_delivery_last_name || appStore.user.c_last_name,
      firstname: appStore.user.c_delivery_first_name || appStore.user.c_first_name,
      address: appStore.user.c_delivery_address || appStore.user.c_address,
      city: appStore.user.c_delivery_city || appStore.user.c_city,
      zipcode: appStore.user.c_delivery_zip_code || appStore.user.c_zip_code,
      country: appStore.user.c_delivery_country || appStore.user.c_country
    }
  }
  emit('go-to-next-step')
}
function goBackToShop() {
  router.push('/shop')
}

function setWinesImagesLoaded(wineId) {
  winesImagesLoaded.value[wineId] = true
}

async function updateBottleNumbers() {
  cartStore.removeDeliveryArticle()
  cartStore.isUserDeliveryInIDF = isUserDeliveryInIDF(appStore?.user)
  cartStore.isUserSubscribed = appStore?.user?.Subscriptions?.length > 0
  cartStore.isUserDeliveryInFrance = cartStore?.user?.c_country?.toLowerCase() === 'france'
  numberOfBottlesInCart.value = wines.value.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.quantity
  }, 0)
  totalNumberOfBottlesNextDelivery.value = await getTotalNumberOfBottlesNextDelivery(appStore.user)
  currentBottleNumber.value = totalNumberOfBottlesNextDelivery.value + numberOfBottlesInCart.value

  if (cartStore.isUserSubscribed && cartStore.isUserDeliveryInIDF) {
    targetValues.value = [
      6 - totalNumberOfBottlesNextDelivery.value,
      12 - totalNumberOfBottlesNextDelivery.value,
      12,
      Infinity
    ]
  } else if (cartStore.isUserSubscribed && !cartStore.isUserDeliveryInIDF) {
    targetValues.value = [6 - totalNumberOfBottlesNextDelivery.value, 12 - totalNumberOfBottlesNextDelivery.value]
  } else if (!cartStore.isUserSubscribed && cartStore.isUserDeliveryInIDF) {
    targetValues.value = [2, 6, 12, Infinity]
  } else {
    targetValues.value = [2, 6, 12]
  }
  const targetValuesFiltered =
    !cartStore.isUserSubscribed && !cartStore.isUserDeliveryInIDF
      ? targetValues.value.filter(value => value > 0 && value !== Infinity)
      : targetValues.value.filter(value => value > 0 && value !== Infinity && value !== 12)
  targetValuesFormatted.value =
    targetValuesFiltered.length === 1
      ? targetValuesFiltered[0]
      : targetValuesFiltered.slice(0, -1).join(', ') + ' ou ' + targetValuesFiltered.slice(-1)

  numberOfBottlesInCart.value = wines.value.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.quantity
  }, 0)
  totalNumberOfBottlesNextDelivery.value = await getTotalNumberOfBottlesNextDelivery(appStore.user)
  currentBottleNumber.value = totalNumberOfBottlesNextDelivery.value + numberOfBottlesInCart.value
  nextBottleTarget.value =
    targetValues.value.find(value => value >= numberOfBottlesInCart.value) ||
    targetValues.value[targetValues.value.length - 1]
}
</script>
