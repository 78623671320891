import { http } from '@/api/index'

export default {
  register(clientData) {
    return http.post('/register', clientData)
  },
  // Is this method used?
  update(clientData) {
    return http.put('/client', clientData)
  },
  validateEmail(authorization) {
    return http.post('/validate-email', {
      authorization
    })
  },
  sendNewValidationEmail(email) {
    return http.post('/send-validation-email', {
      email
    })
  },
  login(email, password) {
    return http.post('/login', {
      ...(email && { email }),
      ...(password && { password })
    })
  },
  requestPasswordReset(email) {
    return http.post('/request-password-reset', {
      email
    })
  },
  resetPassword(password, passwordConfirm, authorization) {
    return http.post('/reset-password', {
      password,
      passwordConfirm,
      authorization
    })
  },
  requestAccountActivation(email) {
    return http.post('/request-account-activation', {
      email
    })
  },
  activateAccount(clientData, authorization) {
    return http.post('/activate-account', {
      ...clientData,
      authorization
    })
  }
}
