<template>
  <div class="mx-auto max-w-7xl px-4 py-4 sm:px-6 md:px-8" v-if="currentStep">
    <PaymentJourneyTitle :title="currentStep.title" :subtitle="currentStep.subtitle" />
    <PaymentJourneySteps :steps="steps" :current-step-index="currentStepIndex" />
    <component
      @goToNextStep="goToNextStep"
      v-if="currentStep.component"
      :is="currentStep.component"
      v-bind="{ ...currentStep.props }"
      class="mt-4"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useOrderStore } from '@/stores/subscriptionOrder'
import { useAppStateStore } from '@/stores/appState'
import PaymentJourneyTitle from '@/components/subscriptionJourney/PaymentJourneyTitle'
import PaymentJourneySteps from '@/components/subscriptionJourney/PaymentJourneySteps'
import paymentJourneys from '@/const/subscriptionPaymentJourneys'

export default {
  components: {
    PaymentJourneyTitle,
    PaymentJourneySteps
  },
  data() {
    return {
      steps: [],
      currentStepIndex: null
    }
  },
  computed: {
    ...mapState(useOrderStore, {
      order: state => state
    }),
    ...mapState(useAppStateStore, {
      user: state => state.user
    }),
    currentStep() {
      return this.steps[this.currentStepIndex]
    }
  },
  methods: {
    ...mapActions(useOrderStore, ['updateOrder', 'resetOrder']),
    goToNextStep() {
      const nextStep = this.steps[this.currentStepIndex + 1]
      window.scrollTo(0, 0)
      this.$router.push({ query: { ...this.$route.query, step: nextStep.status } })
    }
  },
  watch: {
    '$route.query': {
      handler() {
        if (this.$route.query.paymentJourneyType) {
          window.scrollTo(0, 0)
          this.resetOrder()
          this.paymentJourneyType = this.$route.query.paymentJourneyType || 'subscription'
          this.steps = paymentJourneys.journeys[this.paymentJourneyType]
          this.updateOrder({ paymentJourneyType: this.paymentJourneyType })
        } else {
          if (!this.paymentJourneyType) {
            this.paymentJourneyType = this.order.paymentJourneyType || 'subscription'
            this.updateOrder({ paymentJourneyType: this.paymentJourneyType })
          }
          this.steps = paymentJourneys.journeys[(this.order || {}).paymentJourneyType || 'subscription']
        }

        // Step Index
        let urlStepIndex = this.steps.findIndex(s => s.status === this.$route.query.step)

        while (urlStepIndex > 0) {
          if (
            !this.steps[urlStepIndex].prerequisitesValidates ||
            this.steps[urlStepIndex].prerequisitesValidates(this.order, this.user)
          ) {
            break
          }
          --urlStepIndex
        }
        this.currentStepIndex = urlStepIndex >= 0 ? urlStepIndex : 0

        this.$router.push({
          query: {
            ...this.$route.query,
            step: this.currentStep.status,
            paymentJourneyType: undefined
          }
        })
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
