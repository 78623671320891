<template>
  <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-xl font-extrabold text-gray-900">Activer mon abonnement cadeau</h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700"> Adresse e-mail </label>
            <div class="mt-1">
              <input
                id="email"
                v-model="email"
                name="email"
                type="email"
                autocomplete="email"
                required=""
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>
          </div>

          <div>
            <Button @clicked="activateGift" :loading="loading"> Activer </Button>
          </div>
        </form>
      </div>
    </div>
    <div class="lg:flex justify-center" v-if="isEmailSent">
      <p class="text-center text-lg font-extrabold text-secondary tracking-wider mt-8">
        Un email vous permettant de valider votre compte vient de vous être envoyé
      </p>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button'
import authenticationApi from '@/api/authentication'
import { mapActions } from 'pinia'
import { useToastStore } from '@/stores/toast'

export default {
  components: {
    Button
  },
  data() {
    return {
      loading: false,
      email: '',
      isEmailSent: false
    }
  },
  mounted() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email
      this.$router.push({
        query: {
          ...this.$route.query,
          email: undefined
        }
      })
    }
  },
  methods: {
    ...mapActions(useToastStore, ['showToast']),
    async activateGift() {
      this.loading = true
      try {
        await authenticationApi.requestAccountActivation(this.email)
        this.isEmailSent = true
      } catch (err) {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: err.response.data.message
        })
      }
      this.loading = false
    }
  }
}
</script>
